import { Tabs } from 'antd';
import { Trans } from '@lingui/macro';

import { OrganizationList, Security, UserData, ExportData } from '@components';

import styles from './ProfilePage.module.scss';

const ProfilePage = () => {
  return (
    <div className={styles.container}>
      <Tabs
        defaultActiveKey="1"
        size="large"
        items={[
          {
            key: '1',
            label: (
              <span>
                <Trans>Personal information</Trans>
              </span>
            ),
            children: <UserData />,
          },
          {
            key: '2',
            label: (
              <span>
                <Trans>Security</Trans>
              </span>
            ),
            children: <Security />,
          },
          {
            key: '3',
            label: (
              <span>
                <Trans>Organizations</Trans>
              </span>
            ),
            children: <OrganizationList />,
          },
          {
            key: '4',
            label: (
              <span>
                <Trans>Export data</Trans>
              </span>
            ),
            children: <ExportData />,
          },
        ]}
      />
    </div>
  );
};

export default ProfilePage;
