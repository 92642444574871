import { createContext } from 'react';

const defaultContextValue = {} as UserContextType;

type UserState = {
  hasFetched: boolean;
  loggedIn: boolean;
  user: User | null;
  language: LanguageCode;
};

type UserAction = {
  type: string;
  user?: User | null;
  language?: LanguageCode;
};

type UserContextType = {
  state: UserState;
  dispatch: React.Dispatch<UserAction>;
};

export const UserContext = createContext<UserContextType>(defaultContextValue);

export const initialUserState: UserState = {
  hasFetched: false,
  loggedIn: false,
  user: null,
  language: (localStorage.getItem('language') as LanguageCode) || 'en',
};

export const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        hasFetched: true,
        loggedIn: !!action.user,
        user: action.user as User | null,
      };
    // case 'LOGOUT':
    //   return {
    //     ...state,
    //     loggedIn: false,
    //     user: null,
    //   };
    case 'SET_LANGUAGE':
      localStorage.setItem('language', action.language as LanguageCode);
      return {
        ...state,
        language: action.language as LanguageCode,
      };
    default:
      return state;
  }
};

export const setUser = (user: User | null) => ({
  type: 'SET_USER',
  user,
});

// export const logoutUser = () => ({
//   type: 'LOGOUT',
// });

export const setLanguage = (language: LanguageCode) => ({
  type: 'SET_LANGUAGE',
  language,
});
