import { ReactNode } from 'react';
import { Select, Spin } from 'antd';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { DatePicker, CallItemsChart } from '@components';

import {
  useMoment,
  useAnalyticsOptions,
  useAnalyticsData,
  useStandardDateFormat,
} from '@hooks';

import styles from './AnalyticsPage.module.scss';

const { RangePicker } = DatePicker;

const AnalyticsPage = () => {
  const { _ } = useLingui();
  const moment = useMoment();

  const {
    defaultAnalyticsDateRange,
    analyticsOption,
    setAnalyticsOption,
    analyticsFrequency,
    setAnalyticsFrequency,
    analyticsDateRange,
    setAnalyticsDateRange,
  } = useAnalyticsOptions();

  const { isSuccess, data, colorByStatusName } = useAnalyticsData({
    defaultAnalyticsDateRange,
    analyticsOption,
    analyticsFrequency,
    analyticsDateRange,
  });

  const dateRangeFormat = useStandardDateFormat();

  return (
    <div className={styles.container}>
      <div>
        <Trans>Show analytics for current</Trans>
        <Select
          className={styles.select1}
          options={[
            { value: 'organizations', label: _(msg`Organization`) },
            { value: 'projects', label: _(msg`Project`) },
            { value: 'flows', label: _(msg`Flow`) },
          ]}
          value={analyticsOption}
          onChange={(value: string) => setAnalyticsOption(value)}
          data-testid="option-select"
          dropdownRender={(OriginDropdown) => (
            <div data-testid="option-select-dropdown">{OriginDropdown}</div>
          )}
        />
        <Trans>with</Trans>
        <Select
          className={styles.select2}
          options={[
            { value: 'daily', label: _(msg`Daily`) },
            { value: 'weekly', label: _(msg`Weekly`) },
            { value: 'monthly', label: _(msg`Monthly`) },
          ]}
          value={analyticsFrequency}
          onChange={(value: string) => setAnalyticsFrequency(value)}
          data-testid="frequency-select"
          dropdownRender={(OriginDropdown) => (
            <div data-testid="frequency-select-dropdown">{OriginDropdown}</div>
          )}
        />
        <Trans>frequency</Trans> <Trans>in time range</Trans>
        <RangePicker
          className={styles.rangePicker}
          format={dateRangeFormat}
          value={
            analyticsDateRange
              ? [moment(analyticsDateRange?.[0]), moment(analyticsDateRange?.[1])]
              : defaultAnalyticsDateRange
          }
          onChange={(value: moment.Moment | null) => {
            setAnalyticsDateRange(value || defaultAnalyticsDateRange);
          }}
          data-testid="daterange-picker"
          panelRender={(OriginPanel: ReactNode) => {
            return <div data-testid="daterange-panel">{OriginPanel}</div>;
          }}
        />
      </div>
      <div className={styles.chartTitle}>
        <Trans>Number of node calls</Trans>
      </div>
      {!isSuccess && (
        <div className={styles.spinner}>
          <Spin />
        </div>
      )}
      {isSuccess &&
        (data.length > 0 ? (
          <CallItemsChart data={data} colorByStatusName={colorByStatusName} />
        ) : (
          <div className={styles.noDataText}>
            <Trans>There are no node calls</Trans>
          </div>
        ))}
    </div>
  );
};

export default AnalyticsPage;
