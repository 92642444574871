import { Select, Slider } from 'antd';
import { Trans } from '@lingui/macro';

import styles from './DashboardConfiguration.module.scss';

const SLIDER_VALUE_TO_TIME_MAPPING: { [key: number]: number } = {
  2: 5 * 1000,
  3: 10 * 1000,
  4: 30 * 1000,
  5: 60 * 1000,
  6: 5 * 60 * 1000,
  7: 10 * 60 * 1000,
};

const TIME_TO_SLIDER_VALUE_MAPPING = Object.fromEntries(
  Object.entries(SLIDER_VALUE_TO_TIME_MAPPING).map((a) => a.reverse()),
);

type DashboardConfigurationProps = {
  dashboardConfiguration: DashboardConfiguration;
  setDashboardConfiguration: (dashboardConfiguration: DashboardConfiguration) => void;
};

const DashboardConfiguration = ({
  dashboardConfiguration,
  setDashboardConfiguration,
}: DashboardConfigurationProps) => {
  const { refreshValue } = dashboardConfiguration;
  console.log('dashboardConfiguration:', dashboardConfiguration);
  console.log('refreshValue:', refreshValue);

  const sliderValue = TIME_TO_SLIDER_VALUE_MAPPING[refreshValue];
  let selectValue = refreshValue;
  if (selectValue !== 'refresh-disabled') {
    selectValue = 'refresh-enabled';
  }

  return (
    <div className={styles.container}>
      <Trans>Auto refresh table:</Trans>
      <Select
        className={styles.select}
        options={[
          { label: <Trans>Off</Trans>, value: 'refresh-disabled' },
          { label: <Trans>On</Trans>, value: 'refresh-enabled' },
        ]}
        value={selectValue}
        onChange={(value: string) => {
          let refreshValue: number | string = value;
          if (value !== 'refresh-disabled') {
            refreshValue = 30 * 1000;
          }
          setDashboardConfiguration({
            ...dashboardConfiguration,
            refreshValue,
          });
        }}
      />
      {refreshValue !== 'refresh-disabled' && (
        <Slider
          className={styles.slider}
          min={2}
          max={7}
          step={null}
          marks={{
            2: '5s',
            3: '10s',
            4: '30s',
            5: '60s',
            6: '5m',
            7: '10m',
          }}
          tooltip={{ open: false }}
          value={sliderValue}
          onChange={(value: number) => {
            const refreshValue = SLIDER_VALUE_TO_TIME_MAPPING[value];
            setDashboardConfiguration({
              ...dashboardConfiguration,
              refreshValue,
            });
          }}
        />
      )}
    </div>
  );
};

export default DashboardConfiguration;
