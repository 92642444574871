import React, { useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from '@components';

import { ROUTES } from '@config';

import { useUserState } from '@hooks';

type PageContainerProps = {
  noLayout?: boolean;
  children: React.ReactNode;
};

export const PageContainer = ({ noLayout = false, children }: PageContainerProps) => {
  const { hasFetched, loggedIn } = useUserState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!hasFetched) {
    return null;
  }

  // Only display layout to logged in users
  const displayLayout = !noLayout && loggedIn;
  return displayLayout ? <PageLayout>{children}</PageLayout> : children;
};

type AuthPageContainerProps = {
  noLayout?: boolean;
  children: React.ReactNode;
};

export const AuthPageContainer = ({
  noLayout = false,
  children,
}: AuthPageContainerProps) => {
  const { hasFetched, loggedIn } = useUserState();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasFetched && !loggedIn) {
      navigate(ROUTES.LOGIN);
    }
  }, [hasFetched, loggedIn, navigate]);

  if (!hasFetched || !loggedIn) {
    return null;
  }

  return (
    <Fragment>
      <div data-testid="user-loaded"></div>
      <PageContainer noLayout={noLayout}>{children}</PageContainer>
    </Fragment>
  );
};

type NoAuthPageContainerProps = {
  noLayout?: boolean;
  children: React.ReactNode;
};

export const NoAuthPageContainer = ({
  noLayout = false,
  children,
}: NoAuthPageContainerProps) => {
  const { hasFetched, loggedIn } = useUserState();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasFetched && loggedIn) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [hasFetched, loggedIn, navigate]);

  if (!hasFetched || loggedIn) {
    return null;
  }

  return <PageContainer noLayout={noLayout}>{children}</PageContainer>;
};
