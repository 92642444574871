const QUERY_KEYS = {
  ANALYTICS: 'ANALYTICS',
  APP_INFO: 'APP_INFO',
  CONNECTIONS: 'CONNECTIONS',
  FLOWS: 'FLOWS',
  FLOW_CALL: 'FLOW_CALL',
  FLOW_CALLS: 'FLOW_CALLS',
  FLOW_SCHEMA: 'FLOW_SCHEMA',
  NODE_ACTION_INPUT_FIELDS: 'NODE_ACTION_INPUT_FIELDS',
  NODE_ACTION_OUTPUT_FIELDS: 'NODE_ACTION_OUTPUT_FIELDS',
  NODE_CALL_INPUT_DETAIL: 'NODE_CALL_INPUT_DETAIL',
  NODE_CALL_OUTPUT_DETAIL: 'NODE_CALL_OUTPUT_DETAIL',
  NODE_CONNECTION_INPUT_FIELDS: 'NODE_CONNECTION_INPUT_FIELDS',
  NODE_CONNECTION_OPTIONS: 'NODE_CONNECTION_OPTIONS',
  NODE_RESOURCES: 'NODE_RESOURCES',
  ORGANIZATIONS: 'ORGANIZATIONS',
  ORGANIZATION_CONNECTIONS: 'ORGANIZATION_CONNECTIONS',
  // ORGANIZATION_WEBHOOKS: 'ORGANIZATION_WEBHOOKS',
  PROJECTS: 'PROJECTS',
  USER_DATA: 'USER_DATA',
  // WEBHOOK_DETAIL: 'WEBHOOK_DETAIL',
};

export default QUERY_KEYS;
