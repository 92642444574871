import { useRef } from 'react';
import { Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useParam, useSetNewPassword } from '@hooks';

import styles from './ResetPasswordForm.module.scss';

const ResetPasswordForm = () => {
  const { _ } = useLingui();

  const uuid = useParam('uuid');
  const token = useParam('token');

  const setNewPassword = useSetNewPassword();

  const password1 = useRef('');
  const password2 = useRef('');

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setNewPassword({
      uuid: uuid || '',
      token: token || '',
      password1: password1.current,
      password2: password2.current,
    });
  };

  return (
    <form className={styles.formContainer} onSubmit={onSubmit}>
      <div className={styles.title}>
        <Trans>Set new password</Trans>
      </div>
      <Input
        className={styles.input}
        prefix={<LockOutlined />}
        size="large"
        type="password"
        name="password1"
        onChange={(event) => (password1.current = event.target.value)}
        placeholder={_(msg`New password`)}
        required
        minLength={8}
        data-testid="password1-input"
      />
      <Input
        className={styles.input}
        prefix={<LockOutlined />}
        size="large"
        type="password"
        name="password2"
        onChange={(event) => (password2.current = event.target.value)}
        placeholder={_(msg`Confirm new password`)}
        required
        minLength={8}
        data-testid="password2-input"
      />
      <Button
        className={`${styles.button} button-theme2`}
        type="primary"
        shape="round"
        htmlType="submit"
        data-testid="submit-button"
      >
        <Trans>Proceed</Trans>
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
