import { useMemo, TdHTMLAttributes } from 'react';
import { Table } from 'antd';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { StatusTag } from '@components';

import { useMoment } from '@hooks';

import {
  dayMonthYearHourMinuteSecond,
  getTableData,
  getRowClassNameByStatus,
  NODE_CALLS_TABLE_SCROLL_SETTINGS,
} from '@utils';

import styles from './NodeCallsTable.module.scss';

import NodeCallDetail from './NodeCallDetail';

type NodeCallsTableProps = {
  nodeCalls: NodeCall[];
};

const NodeCallsTable = ({ nodeCalls }: NodeCallsTableProps) => {
  const { _ } = useLingui();
  const moment = useMoment();

  const columns = useMemo(
    () => [
      {
        title: _(msg`ID`),
        width: '65%',
        dataIndex: 'uuid',
        key: 'id',
      },
      {
        title: _(msg`Name`),
        width: '35%',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: _(msg`Status`),
        width: '150px',
        dataIndex: 'status',
        key: 'status',
        render: (status: CallStatus) => <StatusTag status={status} />,
      },
      {
        title: _(msg`Start`),
        width: '250px',
        dataIndex: 'start',
        key: 'start',
        render: (start: string) => dayMonthYearHourMinuteSecond(moment(start)),
      },
      {
        title: _(msg`End`),
        width: '250px',
        dataIndex: 'end',
        key: 'end',
        render: (end: string) => {
          if (!end) return '';
          return dayMonthYearHourMinuteSecond(moment(end));
        },
      },
    ],
    [_, moment],
  );

  return (
    <div className={styles.container}>
      <Table
        className="node-calls-table"
        loading={!nodeCalls}
        columns={columns}
        dataSource={nodeCalls ? getTableData(nodeCalls) : undefined}
        scroll={NODE_CALLS_TABLE_SCROLL_SETTINGS}
        rowClassName={(nodeCall: NodeCall) => getRowClassNameByStatus(nodeCall.status)}
        pagination={false}
        expandable={{
          columnWidth: '40px',
          expandRowByClick: true,
          expandedRowRender: (nodeCall: NodeCall) => (
            <NodeCallDetail nodeCall={nodeCall} />
          ),
        }}
        data-testid="node-calls-table"
        onRow={() =>
          ({
            'data-testid': 'table-row',
          }) as TdHTMLAttributes<HTMLTableDataCellElement>
        }
      />
    </div>
  );
};

export default NodeCallsTable;
