import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@config';

import { useUserState } from '@hooks';

const IndexPage = () => {
  const { hasFetched, loggedIn } = useUserState();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasFetched) {
      if (!loggedIn) {
        navigate(ROUTES.LOGIN, { replace: true });
      } else {
        navigate(ROUTES.DASHBOARD, { replace: true });
      }
    }
  }, [hasFetched, loggedIn, navigate]);

  return null;
};

export default IndexPage;
