import { useMemo, TdHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tooltip, Button } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { StatusTag } from '@components';

import { ROUTES } from '@config';

import { useMoment, useFlowCalls } from '@hooks';

import {
  dayMonthYearHourMinuteSecond,
  getTableData,
  getRowClassNameByStatus,
  FLOW_CALLS_TABLE_SCROLL_SETTINGS_1,
  FLOW_CALLS_TABLE_SCROLL_SETTINGS_2,
} from '@utils';

import styles from './FlowCallsTable.module.scss';

type FlowCallsTableProps = {
  schemaVisible: boolean;
  dashboardConfiguration: DashboardConfiguration;
};

const FlowCallsTable = ({
  schemaVisible,
  dashboardConfiguration,
}: FlowCallsTableProps) => {
  const { _ } = useLingui();
  const moment = useMoment();
  const { isLoading, flowCalls, total, page, setPage } =
    useFlowCalls(dashboardConfiguration);

  const columns = useMemo(
    () => [
      {
        title: '',
        width: '80px',
        render: ({ _id: flowCallId, uuid: flowUuid }: FlowCall) => {
          return (
            <Link to={`${ROUTES.FLOW}/${flowUuid}${ROUTES.FLOW_CALL}/${flowCallId}`}>
              <Tooltip title={_(msg`View detail`)}>
                <Button
                  type="text"
                  shape="circle"
                  icon={<FileSearchOutlined className={styles.viewDetailIcon} />}
                  data-testid="detail-button"
                />
              </Tooltip>
            </Link>
          );
        },
      },
      {
        title: _(msg`ID`),
        width: '50%',
        dataIndex: '_id',
        key: 'id',
      },
      {
        title: _(msg`Name`),
        width: '50%',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: _(msg`Status`),
        width: '150px',
        dataIndex: 'status',
        key: 'status',
        render: (status: CallStatus) => <StatusTag status={status} />,
      },
      {
        title: _(msg`Start`),
        width: '250px',
        dataIndex: 'start',
        key: 'start',
        render: (start: string) => dayMonthYearHourMinuteSecond(moment(start)),
      },
      {
        title: _(msg`End`),
        width: '250px',
        dataIndex: 'end',
        key: 'end',
        render: (end: string) => {
          if (!end) return '';
          return dayMonthYearHourMinuteSecond(moment(end));
        },
      },
    ],
    [_, moment],
  );

  return (
    <div className={styles.container}>
      <Table
        className="flow-calls-table"
        loading={isLoading}
        columns={columns}
        dataSource={getTableData(flowCalls)}
        scroll={
          schemaVisible
            ? FLOW_CALLS_TABLE_SCROLL_SETTINGS_1
            : FLOW_CALLS_TABLE_SCROLL_SETTINGS_2
        }
        rowClassName={(flowCall: FlowCall) => getRowClassNameByStatus(flowCall.status)}
        pagination={{
          current: page,
          total,
          pageSize: 100,
          showSizeChanger: false,
        }}
        data-testid="flow-calls-table"
        onRow={() =>
          ({
            'data-testid': 'table-row',
          }) as TdHTMLAttributes<HTMLTableDataCellElement>
        }
        onChange={(pagination) => {
          setPage(pagination.current as number);
        }}
      />
    </div>
  );
};

export default FlowCallsTable;
