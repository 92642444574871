import moment from 'moment';

export const toStandardDate = (date: moment.Moment) => {
  return date.format('YYYY-MM-DD');
};

export const toStandardDatetime = (date: moment.Moment) => {
  return date.format('YYYY-MM-DDTHH:mm:ss');
};

export const dayMonthYear = (date: moment.Moment) => {
  return date.format('L');
};

export const dayMonthYearHourMinute = (date: moment.Moment) => {
  return date.format('L LT');
};

export const dayMonthYearHourMinuteSecond = (date: moment.Moment) => {
  return date.format('LL LTS');
};

export const monthYear = (date: moment.Moment) => {
  // The reason why we use this instead of MMMM
  // is that in some languages, MMMM can return the month name
  // in different form than we want,
  // e.g. 'ledna' instead of 'leden' in Czech
  const monthName = new Date(date.toDate()).toLocaleString(date.locale(), {
    month: 'long',
  });
  return monthName + ' ' + moment(date).format('YYYY');
};
