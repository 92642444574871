import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';

import { ROUTES } from '@config';

import { useGetTranslatedMessage } from '@hooks';

import { messages } from '@locales';

export const useMessage = () => {
  const { message } = App.useApp();
  return message;
};

const MESSAGE_TYPES = ['success', 'error', 'info', 'warning', 'loading'];
type MessageType = 'success' | 'error' | 'info' | 'warning' | 'loading';

export const useDisplayResponseMessage = () => {
  const t = useGetTranslatedMessage();
  const message = useMessage();

  // Typical message looks like 'users.login.error',
  // but it can also look like 'users.login.error.unauthorized',
  // therefore we check whether at least one of the last two strings
  // matches some message type, if it does, we retrieve the message
  // from '@locales' using the 'useGetTranslatedMessage' hook
  return <T extends keyof typeof messages>(
    messageName: T,
    ...messageArgs: Parameters<(typeof messages)[T]>
  ) => {
    const messageKeys = messageName.split('.').slice().reverse();
    let idx = MESSAGE_TYPES.indexOf(messageKeys[0]);
    if (idx === -1 && messageKeys.length > 0) {
      // Check second to last string
      idx = MESSAGE_TYPES.indexOf(messageKeys[1]);
    }
    if (idx > -1) {
      const messageType = MESSAGE_TYPES[idx] as MessageType;
      const messageText = t(messageName, ...messageArgs);
      message[messageType](messageText);
      return true;
    }
  };
};

export const useParam = (param: string) => {
  return useParams()[param];
};

export const useParamIdOrRedirect = (
  param: string = 'id',
  allowString: boolean = false,
) => {
  const navigate = useNavigate();
  let id: string | number | undefined = useParam(param);
  let valid = true;
  if (!allowString) {
    id = Math.floor(Number(id));
    valid = !isNaN(id) && id > 0;
  } else {
    valid = !!id;
  }

  useEffect(() => {
    if (!valid) {
      navigate(ROUTES.INDEX);
    }
  }, [navigate, valid]);

  if (valid) {
    return id;
  }
};

export const useLocalStorage = <T>(key: string, defaultValue: T) => {
  const [localStorageValue, setLocalStorageValue] = useState(() => {
    const value = localStorage.getItem(key);
    console.log('value:', value);
    return value === null ? defaultValue : JSON.parse(value);
  });

  const setLocalStorageStateValue = (newValue: T) => {
    console.log('newValue:', newValue);
    console.log('JSON.stringify(newValue):', JSON.stringify(newValue));
    localStorage.setItem(key, JSON.stringify(newValue));
    setLocalStorageValue(newValue);
  };

  return [localStorageValue, setLocalStorageStateValue];
};

export const useEventListener = <T extends (event: Event) => void>(
  eventName: string,
  handler: T,
  elementRef: { current: HTMLElement | null },
) => {
  const savedHandler: { current?: T } = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const element = elementRef.current;
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = (event: Event) => {
      if (savedHandler.current) {
        savedHandler.current(event);
      }
    };

    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, elementRef]);
};
