/* eslint-disable  @typescript-eslint/no-explicit-any */

type Option = {
  [key: string]: any;
};

export const getSelectOptions = <T extends Option>(
  options?: T[] | null,
  labelProp: keyof T = 'name',
  valueProp: keyof T = 'uuid',
): OptionLabelValue[] => {
  if (!options) return [];
  return options.map((option) => ({
    ...option,
    label: option[labelProp] as string,
    title: option[labelProp] as string,
    value: option[valueProp] as string | number,
  }));
};
