import { useState } from 'react';
import { Card, Button, Form, Input } from 'antd';
import { isEqual } from 'lodash';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { AvatarUpload, FormItem } from '@components';

import { useMessage, useUser, useSaveUserData } from '@hooks';

import styles from './UserData.module.scss';

const getFormValues = (user: User) => {
  return {
    email: user.email || '',
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    // role: user.role || '',
    // phone: user.phone || '',
  };
};

const UserData = () => {
  const { _ } = useLingui();
  const message = useMessage();
  const user = useUser();
  const [form] = Form.useForm();
  const [displaySave, setDisplaySave] = useState(false);

  const initialValues = getFormValues(user);

  const onFormChange = () => {
    const currentValues = form.getFieldsValue();
    const hasChanged = !isEqual(initialValues, currentValues);
    if ((hasChanged && !displaySave) || (!hasChanged && displaySave)) {
      setDisplaySave(!displaySave);
    }
  };

  const saveData = useSaveUserData();
  const onSave = async () => {
    try {
      const data = await form.validateFields();
      const success = await saveData(data);
      if (success) setDisplaySave(false);
    } catch (error) {
      console.error(error);
      message.error(
        _(msg`The form contains errors, please fix them before submitting`),
      );
    }
  };

  return (
    <div className={styles.container}>
      <Card title={_(msg`Profile image`)} data-testid="avatar-container">
        <AvatarUpload />
      </Card>
      <Card
        title={
          <div className={styles.cardTitleRow}>
            <span>
              <Trans>Personal Information</Trans>
            </span>
            <Button
              shape="round"
              type="primary"
              style={{
                opacity: displaySave ? 100 : 0,
                visibility: displaySave ? 'visible' : 'hidden',
              }}
              onClick={() => onSave()}
              data-testid="save-button"
            >
              <Trans>Save</Trans>
            </Button>
          </div>
        }
      >
        <Form form={form} initialValues={initialValues} onChange={() => onFormChange()}>
          <div className={styles.fieldsContainer}>
            <FormItem name="email" label={_(msg`Email`)}>
              <Input disabled data-testid="email-input" />
            </FormItem>
            <FormItem
              name="first_name"
              label={_(msg`First name`)}
              rules={[
                { max: 40, message: _(msg`Value must be up to 40 characters`) },
                { required: true, message: _(msg`This field is required`) },
              ]}
            >
              <Input data-testid="first-name-input" />
            </FormItem>
            <FormItem
              name="last_name"
              label={_(msg`Last name`)}
              rules={[
                { max: 40, message: _(msg`Value must be up to 40 characters`) },
                { required: true, message: _(msg`This field is required`) },
              ]}
            >
              <Input data-testid="last-name-input" />
            </FormItem>
            {/* <FormItem name="role" label={_(msg`Role`)} rules={[{ max: 40 }]}>
              <Input />
            </FormItem> */}
            {/* <FormItem name="phone" label={_(msg`Phone`)} rules={[{ max: 20 }]}>
            <Input />
            </FormItem> */}
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default UserData;
