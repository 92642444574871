import Dagre from '@dagrejs/dagre';
import { Position } from 'reactflow';
import type { Node, Edge } from 'reactflow';

import { BuilderNodeType, BuilderEdgeType } from '@types';

import { getByUuid } from '@utils';

const NODE_HEIGHT = 120;
const NODE_WIDTH = 200;

const getRoots = (nodes: RawNode[]) => {
  return nodes.filter((node: RawNode) => node.parent === null);
};

type Tree = {
  [key: string]: RawNode[];
};

const getTree = (nodes: RawNode[]) => {
  const tree: Tree = {};
  nodes.forEach((node: RawNode) => {
    const parent = node.parent;
    if (!parent) return;
    if (!(parent in tree)) {
      tree[parent] = [];
    }
    tree[parent].push(node);
  });
  return tree;
};

const traverseTree = ({
  tree,
  root,
  forEachNode = () => {},
  forEachEdge = () => {},
  forEachLevel = () => {},
}: {
  tree: Tree;
  root: RawNode;
  forEachNode?: (node: RawNode) => void;
  forEachEdge?: (node1: RawNode, node2: RawNode) => void;
  forEachLevel?: () => void;
}) => {
  let nodesToTraverse: RawNode[] = [root];
  while (nodesToTraverse.length > 0) {
    const nextNodesToTraverse: RawNode[] = [];
    nodesToTraverse.forEach((node1: RawNode) => {
      forEachNode(node1);
      if (node1.uuid in tree) {
        tree[node1.uuid].forEach((node2: RawNode) => {
          forEachEdge(node1, node2);
          nextNodesToTraverse.push(node2);
        });
      }
    });
    forEachLevel();
    nodesToTraverse = nextNodesToTraverse;
  }
};

export const prepareNodes = (
  nodes: RawNode[],
  relations: {
    ancestor: string;
    descendant: string;
  }[],
): RawNode[] => {
  if (nodes.length === 0) {
    return [];
  }

  const dagreGraph = new Dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'LR', nodesep: 25 });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.uuid, {
      height: NODE_HEIGHT,
      width: NODE_WIDTH,
    });
  });

  const parsedNodes: RawNode[] = nodes.map((node) => ({
    ...node,
    name: node.name || '',
    parent: null,
  }));
  relations.forEach(
    ({ ancestor, descendant }: { ancestor: string; descendant: string }) => {
      const targetNode = parsedNodes.filter(({ uuid }: RawNode) => {
        return uuid === descendant;
      })[0];
      if (targetNode) {
        targetNode.parent = ancestor;
      }
      dagreGraph.setEdge(ancestor, descendant);
    },
  );

  Dagre.layout(dagreGraph);

  const roots = getRoots(parsedNodes);
  const tree = getTree(parsedNodes);

  roots.forEach((root: RawNode) => {
    let depth = 1;
    traverseTree({
      tree,
      root,
      forEachNode: (node: RawNode) => {
        const { x, y } = dagreGraph.node(node.uuid);
        node.defaultX = x - NODE_WIDTH / 2;
        node.defaultY = y - NODE_HEIGHT / 2;
        node.depth = depth;
      },
      forEachLevel: () => {
        depth += 1;
      },
    });
  });

  return parsedNodes;
};

const getNodeData = (
  node: RawNode,
  nodeTypes: NodeType[],
  nodeActions: NodeAction[],
  getNodeClassName?: (node: RawNode) => string,
) => {
  return {
    uuid: node.uuid,
    parent: node.parent,
    nodeType: getByUuid(nodeTypes, node.node_type_resource),
    nodeAction: getByUuid(nodeActions, node.action_resource),
    connection: node.connection,
    className: getNodeClassName ? getNodeClassName(node) : '',
  };
};

const getBuilderNode = (
  node: RawNode,
  nodeTypes: NodeType[],
  nodeActions: NodeAction[],
  getNodeClassName?: (node: RawNode) => string,
) => {
  return {
    id: node.uuid,
    type: BuilderNodeType.baseNode,
    dragHandle: '.base-node-drag-handle',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    data: getNodeData(node, nodeTypes, nodeActions, getNodeClassName),
  };
};

export const getBuilderNodes = ({
  nodes,
  builderNodes = [],
  nodeTypes,
  nodeActions,
  editable,
  getNodeClassName,
}: {
  nodes: RawNode[];
  nodeTypes: NodeType[];
  nodeActions: NodeAction[];
  builderNodes?: Node<NodeData>[];
  editable?: boolean;
  getNodeClassName?: (node: RawNode) => string;
}) => {
  // Remove nodes
  const activeNodeUuids = new Set(nodes.map((node) => node.uuid));
  builderNodes = builderNodes.filter((builderNode) =>
    activeNodeUuids.has(builderNode.id),
  );

  // Add nodes
  const existingNodeUuids = new Set(builderNodes.map((builderNode) => builderNode.id));
  nodes.forEach((node) => {
    if (!existingNodeUuids.has(node.uuid)) {
      builderNodes.push(
        getBuilderNode(
          node,
          nodeTypes,
          nodeActions,
          getNodeClassName,
        ) as Node<NodeData>,
      );
    }
  });

  // Replace data
  builderNodes.forEach((builderNode: Node<NodeData>) => {
    const node = nodes.find((node: RawNode) => node.uuid === builderNode.id);
    if (node) {
      builderNode.data = {
        ...builderNode.data,
        ...getNodeData(node, nodeTypes, nodeActions, getNodeClassName),
      };
    }
  });

  // Set props
  const roots = getRoots(nodes);
  if (roots.length > 0) {
    const nodeProps: { [id: string]: Partial<Node<NodeData>> } = {};

    builderNodes.forEach((builderNode: Node<NodeData>) => {
      nodeProps[builderNode.id] = builderNode;
      if (builderNode.position) {
        const targetProps = nodeProps[builderNode.id] as Node<NodeData>;
        targetProps.position = builderNode.position;
        targetProps.data.hasLoadedPosition = true;
      }
    });

    const tree = getTree(nodes);

    roots.forEach((root: RawNode) => {
      traverseTree({
        tree,
        root,
        forEachNode: (node: RawNode) => {
          if (!nodeProps[node.uuid]) {
            nodeProps[node.uuid] = {};
          }

          // Set position
          if (!nodeProps[node.uuid].position) {
            const parentProps = node.parent ? nodeProps[node.parent] : {};

            // This means that parent has already been rendered
            // and this node was just added, therefore,
            // we set its position based on the position of the parent node
            // (and number of children)
            if (parentProps?.data?.hasLoadedPosition) {
              const parentPosition = parentProps?.position as { x: number; y: number };
              const parentChildren = parentProps?.data?.children?.length || 0;

              nodeProps[node.uuid].position = {
                x: parentPosition.x + 250,
                y: parentPosition.y + (parentChildren - 1) * 10,
              };
            } else {
              nodeProps[node.uuid].position = {
                x: node.defaultX || 0,
                y: node.defaultY || 0,
              };
            }
          }

          // Set children
          nodeProps[node.uuid].data = {
            ...nodeProps[node.uuid].data,
            children: tree?.[node.uuid] || [],
          } as NodeData;
        },
      });
    });

    builderNodes = builderNodes.map((builderNode) => {
      return {
        ...builderNode,
        ...nodeProps[builderNode.id],
      };
    });
  }

  // Render '+' node
  if (editable && nodes.length === 0) {
    builderNodes.push({
      id: '+',
      type: BuilderNodeType.createNode,
      position: { x: 0, y: 0 },
      data: {
        uuid: '+',
        parent: null,
        children: [],
      },
    });
  }

  return builderNodes;
};

export const getBuilderEdges = ({
  nodes,
  editable,
}: {
  nodes: RawNode[];
  editable?: boolean;
}) => {
  const roots = getRoots(nodes);
  if (roots.length === 0) {
    return [];
  }

  const tree = getTree(nodes);
  const builderEdges: Edge<EdgeData>[] = [];

  roots.forEach((root: RawNode) => {
    traverseTree({
      tree,
      root,
      forEachEdge: (node1: RawNode, node2: RawNode) => {
        builderEdges.push({
          id: `e${node1.uuid}-${node2.uuid}`,
          type: editable ? BuilderEdgeType.deletableEdge : BuilderEdgeType.baseEdge,
          source: node1.uuid.toString(),
          target: node2.uuid.toString(),
          data: { hovered: false },
        });
      },
    });
  });

  return builderEdges.map((edge) => ({ ...edge, animated: true }));
};
