import {
  memo,
  // MouseEvent,
  // useState,
} from 'react';
// import { Popover, Tooltip, Button } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
import {
  // msg,
  Trans,
} from '@lingui/macro';
// import { useLingui } from '@lingui/react';

import {
  // CreateConnectionForm,
  // InputField,
  SearchableSelect,
} from '@components';

import {
  // useActiveOrganizationUuid,
  useNodeResources,
  useSetNodeType,
  useSetNodeAction,
  // useNodeConnectionOptions,
  // useSetNodeConnection,
  // useNodeConnectionInputFields,
  // useNodeActionInputFields,
  // useCreateConnection,
} from '@hooks';

import { getSelectOptions } from '@utils';

import styles from './NodeConfiguration.module.scss';

type NodeConfigurationProps = {
  data: NodeData;
};

const NodeConfiguration = ({ data }: NodeConfigurationProps) => {
  // const { _ } = useLingui();
  const { nodeTypes, nodeActions } = useNodeResources();
  const setNodeType = useSetNodeType(data);
  const setNodeAction = useSetNodeAction(data);

  // const [creatingConnection, setCreatingConnection] = useState(false);
  // const connectionOptions = useNodeConnectionOptions(data.nodeType);
  // const setNodeConnection = useSetNodeConnection(data);

  // const connectionInputFields = useNodeConnectionInputFields(data.nodeType);
  // const actionInputFields = useNodeActionInputFields(data.nodeAction);

  // const organizationUuid = useActiveOrganizationUuid();
  // const createConnection = useCreateConnection();
  // const onCreateConnection = async (
  //   name: string,
  //   fields: { [key: string]: string },
  // ) => {
  //   const success = await createConnection({
  //     nodetype: data.nodeType?.uuid as string,
  //     organization: organizationUuid as string,
  //     name: name,
  //     data: fields,
  //   });
  //   if (success) setCreatingConnection(false);
  // };

  return (
    <div>
      <div>
        <SearchableSelect
          className={styles.select}
          placeholder={<Trans>Node type</Trans>}
          options={getSelectOptions(nodeTypes)}
          value={data.nodeType?.uuid}
          onChange={(nodeTypeUuid) => {
            setNodeType(nodeTypeUuid);
          }}
        />
      </div>
      <div>
        <SearchableSelect
          className={styles.select}
          placeholder={<Trans>Action</Trans>}
          options={getSelectOptions(
            nodeActions?.filter((actionResource: NodeAction) => {
              return actionResource.node_type__uuid === data.nodeType?.uuid;
            }),
          )}
          value={data.nodeAction?.uuid}
          onChange={(nodeActionUuid) => {
            setNodeAction(nodeActionUuid);
          }}
        />
      </div>
      {/* <div className={styles.inputFieldsTitle}>
        <Trans>Configure node connection</Trans>
      </div> */}
      {/* <div className={styles.connectionRow}>
        <SearchableSelect
          className={styles.connectionSelect}
          placeholder={<Trans>Connection</Trans>}
          options={getSelectOptions(connectionOptions)}
          value={data.connection}
          onChange={(connectionUuid) => {
            setNodeConnection(connectionUuid);
          }}
        />
        <Popover
          trigger={['click']}
          open={creatingConnection}
          onOpenChange={(open) => setCreatingConnection(open)}
          content={
            <CreateConnectionForm
              fields={connectionInputFields}
              onCreateConnection={onCreateConnection}
            />
          }
          placement="right"
          overlayInnerStyle={{ padding: 0 }}
          destroyTooltipOnHide
        >
          <Tooltip title={data.nodeType ? _(msg`Create connection`) : ''}>
            <Button
              className={styles.createConnectionButton}
              type="primary"
              size="small"
              shape="circle"
              icon={<PlusOutlined />}
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setCreatingConnection(true);
              }}
              disabled={!data.nodeType}
            />
          </Tooltip>
        </Popover>
      </div> */}
      {/* {actionInputFields.length > 0 && (
        <>
          <div className={styles.inputFieldsTitle}>
            <Trans>Configure node action</Trans>
          </div>
          {actionInputFields.map((field: FieldConfiguration) => {
            return (
              <div key={field.key} className={styles.inputFieldContainer}>
                <InputField field={field} />
              </div>
            );
          })}
        </>
      )} */}
    </div>
  );
};

const MemoizedNodeConfiguration = memo(NodeConfiguration);
export default MemoizedNodeConfiguration;
