// Should be same as Status enum in backend/mongo/types.py
export enum CodeByStatusName {
  success = 0,
  failed = 1,
  retrying = 2,
  in_progress = 3,
  in_queue = 4,
}

// This is completely unrelated to backend node type
export enum BuilderNodeType {
  baseNode = 'baseNode',
  createNode = 'createNode',
}

export enum BuilderEdgeType {
  baseEdge = 'baseEdge',
  deletableEdge = 'deletableEdge',
}
