import type { MouseEvent } from 'react';
import { getBezierPath, BaseEdge, EdgeLabelRenderer } from 'reactflow';
import type { EdgeProps } from 'reactflow';
import { Tooltip, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useDeleteEdge } from '@hooks';

import styles from './DeletableEdge.module.scss';

const DeletableEdge = ({
  source,
  sourceX,
  sourceY,
  target,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: EdgeProps<EdgeData>) => {
  const { _ } = useLingui();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const deleteEdge = useDeleteEdge();

  const onDeleteEdge = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    deleteEdge(source, target);
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            // Everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: 'all'
            pointerEvents: 'all',
          }}
          className="nodrag nopan nowheel"
        >
          <Tooltip title={_(msg`Delete edge`)}>
            <Button
              className={`${styles.deleteButton} ${
                data?.hovered ? styles.deleteButtonVisible : ''
              }`}
              shape="circle"
              size="small"
              danger
              icon={<CloseOutlined />}
              onClick={onDeleteEdge}
            />
          </Tooltip>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default DeletableEdge;
