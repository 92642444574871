/* eslint-disable  @typescript-eslint/no-explicit-any */

const getterByProperty = (property: string) => {
  return (list: any[] = [], propertyValue?: string | number) => {
    const index = list.map((_: any) => _[property]).indexOf(propertyValue);
    if (index === -1) {
      return null;
    } else {
      return list[index];
    }
  };
};

export const getByUuid = getterByProperty('uuid');

export const trimStringsInObject = (obj: any, maxLength: number) => {
  const copiedObject: any = {};

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      copiedObject[key] = obj[key];
      if (copiedObject[key].length > maxLength) {
        copiedObject[key] = copiedObject[key].substring(0, maxLength) + '...';
      }
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      copiedObject[key] = trimStringsInObject(obj[key], maxLength);
    } else {
      copiedObject[key] = obj[key];
    }
  }

  return copiedObject;
};

// // This is used when relevant data is nested within several objects,
// // we find it and concatenate it to a single array
// export const flattenNestedArrays = (obj: any, result: any = []) => {
//   for (const key in obj) {
//     if (Array.isArray(obj[key])) {
//       result.push(...obj[key]);
//     } else if (typeof obj[key] === 'object' && obj[key] !== null) {
//       flattenNestedArrays(obj[key], result);
//     }
//   }
//   return result;
// };
