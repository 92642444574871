const SentryErrorPage = () => {
  return (
    <button
      onClick={() => {
        throw new Error('Error');
      }}
    >
      Break the world
    </button>
  );
};

export default SentryErrorPage;
