import { Card, Button } from 'antd';
import { Trans } from '@lingui/macro';

const ExportData = () => {
  return (
    <Card
      title={
        <span>
          <Trans>Export data</Trans>
        </span>
      }
    >
      <p>
        <Trans>To export your data, click the button below.</Trans>
      </p>
      <Button type="primary" shape="round" data-testid="export-button">
        <Trans>Export</Trans>
      </Button>
    </Card>
  );
};

export default ExportData;
