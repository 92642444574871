import { memo } from 'react';
import { Tooltip, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useActiveFlow, useCreateRootNode } from '@hooks';

import styles from './CreateNode.module.scss';

const CreateNode = () => {
  const { _ } = useLingui();
  const activeFlow = useActiveFlow() as Flow;
  const createRootNode = useCreateRootNode(activeFlow);

  return (
    <Tooltip title={_(msg`Create root node`)}>
      <Button
        className={styles.button}
        type="primary"
        shape="circle"
        size="large"
        icon={<PlusOutlined className={styles.icon} />}
        onClick={() => createRootNode()}
      />
    </Tooltip>
  );
};

const MemoizedCreateNode = memo(CreateNode);
export default MemoizedCreateNode;
