/* eslint-disable  @typescript-eslint/no-explicit-any */

import axios, { AxiosError } from 'axios';

import { COOKIES } from '@config';

import { setCookie, getRequestHeaders } from '@utils';

export const useRequest = () => {
  return async (
    url: string,
    {
      data = {},
      method = 'get',
      headers = {},
      params = {},
    }: {
      data?: any;
      method?: string;
      headers?: any;
      params?: any;
    } = {},
  ) => {
    try {
      return await axios({
        headers: {
          ...getRequestHeaders(),
          ...headers,
        },
        url,
        data,
        params,
        method,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 401) {
          setCookie(COOKIES.AUTH_TOKEN, '');
        }
      }
      throw error;
    }
  };
};
