const ROUTES = {
  ANALYTICS: '/analytics',
  CONNECTIONS: '/connections',
  DASHBOARD: '/dashboard',
  FLOW: '/flow',
  FLOW_BUILDER: '/flow-builder',
  FLOW_CALL: '/call',
  FORGOT_PASSWORD: '/forgot-password',
  INDEX: '/',
  LOGIN: '/login',
  PROFILE: '/profile',
  RESET_PASSWORD: '/reset-password/:uuid/:token',
  SENTRY_ERROR: '/sentry-error',
  // WEBHOOKS: '/webhooks',
};

export default ROUTES;
