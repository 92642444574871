import React, { useRef, useState } from 'react';
import { Card, Switch, Tooltip, Input, Button, Typography, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useActiveFlow, useUpdateFlow, useMessage, useDeleteFlow } from '@hooks';

import styles from './FlowConfiguration.module.scss';

const { Text } = Typography;

const FlowConfiguration: React.FC = () => {
  const { _ } = useLingui();
  const message = useMessage();
  const flow = useActiveFlow() as Flow;
  const [isEditing, setIsEditing] = useState(false);
  const editedName = useRef('');

  const updateFlow = useUpdateFlow(flow);
  const deleteFlow = useDeleteFlow(flow);

  const onFlowNameChange = () => {
    if (isEditing) {
      const newName = editedName.current;
      if (!newName) {
        message.error(_(msg`Flow name cannot be empty`));
      } else {
        if (newName !== flow.name) {
          updateFlow({ name: editedName.current });
        }
        setIsEditing(false);
      }
    }
  };

  const onFlowEnabledChange = (enabled: boolean) => {
    updateFlow({ enabled });
  };

  const onFlowDelete = () => {
    deleteFlow();
    // message.success(_(msg`Flow deleted successfully`));
  };

  return (
    <div className={styles.container}>
      <Card bordered={false} size="small">
        <div className={styles.flowNameContainer}>
          {isEditing ? (
            <Input
              defaultValue={flow.name}
              onChange={(e) => (editedName.current = e.target.value)}
              onPressEnter={onFlowNameChange}
              onBlur={onFlowNameChange}
              className={styles.nameInput}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          ) : (
            <Text className={styles.flowName} ellipsis={{ tooltip: flow.name }}>
              {flow.name}
            </Text>
          )}
          {!isEditing && (
            <Tooltip title={_(msg`Edit flow name`)}>
              <Button
                className={styles.editButton}
                type="text"
                size="small"
                shape="circle"
                icon={<EditOutlined className={styles.editIcon} />}
                onClick={() => {
                  setIsEditing(true);
                  editedName.current = flow.name;
                }}
              />
            </Tooltip>
          )}
        </div>
        <div className={styles.details}>
          {/* <span>
            <Trans>Interval</Trans>: {flow.interval} <Trans>minutes</Trans>
          </span> */}
          <span className={styles.enabledSwitch}>
            <Trans>Enabled</Trans>:{' '}
            <Switch checked={flow.enabled} onChange={onFlowEnabledChange} />
          </span>
          <Popconfirm
            title={_(msg`Are you sure you want to delete this flow?`)}
            onConfirm={onFlowDelete}
            okText={_(msg`Yes`)}
            cancelText={_(msg`No`)}
            placement="right"
          >
            <Tooltip title={_(msg`Delete flow`)}>
              <Button size="small" shape="circle" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </div>
      </Card>
    </div>
  );
};

export default FlowConfiguration;
