import { Fragment, useMemo } from 'react';
import { Descriptions, Tooltip, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { JsonView } from 'react-json-view-lite';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { saveAs } from 'file-saver';

import { useNodeCallDetail } from '@hooks';

import { trimStringsInObject } from '@utils';

import styles from './NodeCallDetail.module.scss';

import 'react-json-view-lite/dist/index.css';

type NodeCallDetailProps = {
  nodeCall: NodeCall;
};

const MAX_DISPLAYED_STRING_LENGTH = 500;

const NodeCallDetail = ({ nodeCall }: NodeCallDetailProps) => {
  const { _ } = useLingui();
  const { isSuccess, inputData, outputData } = useNodeCallDetail(nodeCall);

  const [trimmedInputData, trimmedOutputData] = useMemo(() => {
    return [
      trimStringsInObject(inputData, MAX_DISPLAYED_STRING_LENGTH),
      trimStringsInObject(outputData, MAX_DISPLAYED_STRING_LENGTH),
    ];
  }, [inputData, outputData]);

  const downloadIOData = (type: 'input' | 'output') => {
    const blob = new Blob([JSON.stringify(type === 'input' ? inputData : outputData)], {
      type: 'application/json',
    });
    saveAs(blob, `${type}.json`);
  };

  if (!isSuccess) {
    return null;
  }

  return (
    <Fragment>
      {nodeCall.errors.length > 0 && (
        <div className={styles.errors}>
          <p>
            <Trans>Errors:</Trans>
          </p>
          <ul>
            {nodeCall.errors.map((error: string, index: number) => {
              return <li key={index}>{error}</li>;
            })}
          </ul>
        </div>
      )}
      <Descriptions className={styles.descriptions} bordered size="small" column={2}>
        <Descriptions.Item
          label={
            <div>
              <Trans>Input</Trans>
              <Tooltip title={_(msg`Download full input`)}>
                <Button
                  onClick={() => downloadIOData('input')}
                  icon={<DownloadOutlined />}
                  size="small"
                  style={{ marginTop: '.2rem' }}
                >
                  <span>
                    <Trans>Download</Trans>
                  </span>
                </Button>
              </Tooltip>
            </div>
          }
          labelStyle={{ verticalAlign: 'top', width: '13rem' }}
          contentStyle={{ verticalAlign: 'top' }}
          span={1}
        >
          <div className={styles.jsonContainer} data-testid="input-data-json">
            <JsonView data={trimmedInputData} />
          </div>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <div>
              <Trans>Output</Trans>
              <Tooltip title={_(msg`Download full output`)}>
                <Button
                  onClick={() => downloadIOData('output')}
                  icon={<DownloadOutlined />}
                  size="small"
                  style={{ marginTop: '.2rem' }}
                >
                  <span>
                    <Trans>Download</Trans>
                  </span>
                </Button>
              </Tooltip>
            </div>
          }
          labelStyle={{ verticalAlign: 'top', width: '13rem' }}
          contentStyle={{ verticalAlign: 'top' }}
          span={1}
        >
          <div className={styles.jsonContainer} data-testid="output-data-json">
            <JsonView data={trimmedOutputData} />
          </div>
        </Descriptions.Item>
      </Descriptions>
    </Fragment>
  );
};

export default NodeCallDetail;
