import { msg } from '@lingui/macro';

const messages = {
  'analytics.fetch.error': () => {
    return msg`An error has occurred while fetching analytics`;
  },
  'app_info.fetch.error': () => {
    return msg`An error has occurred while fetching application information`;
  },
  'connections.fetch.error': () => {
    return msg`An error has occurred while fetching connections`;
  },
  'connections.create.success': () => {
    return msg`A new connection has been created`;
  },
  'connections.create.error': () => {
    return msg`An error has occurred while creating new connection`;
  },
  'connections.delete.success': (connection: Connection) => {
    return msg`Connection ${connection.name} has been deleted`;
  },
  'connections.delete.error': (connection: Connection) => {
    return msg`An error has occurred while deleting connection ${connection.name}`;
  },
  // 'connections.verify.success': (connection: Connection) => {
  //   return msg`The connection ${connection.name} works as expected`;
  // },
  // 'connections.verify.error': (connection: Connection, errorText?: string) => {
  //   return msg`The connection ${connection.name} returned an error: ${errorText}`;
  // },
  // 'connections.disconnect.success': (connection: Connection) => {
  //   return msg`${connection.name} has been disconnected`;
  // },
  // 'connections.disconnect.error': (connection: Connection) => {
  //   return msg`An error has occurred while disconnecting ${connection.name}`;
  // },
  'flows.create.error': () => {
    return msg`An error has occurred while creating flow`;
  },
  'flows.update.error': () => {
    return msg`An error has occurred while updating flow`;
  },
  'flows.delete.error': () => {
    return msg`An error has occurred while deleting flow`;
  },
  'flows.execute.error': (errorText?: string) => {
    return msg`An error has occurred while executing flow: ${errorText}`;
  },
  'flows.fetch.error': () => {
    return msg`An error has occurred while fetching flows`;
  },
  'flows.schema.fetch.error': () => {
    return msg`An error has occurred while fetching flow schema`;
  },
  'flows.schema.create_root_node.error': () => {
    return msg`An error has occurred while creating root node`;
  },
  'flows.schema.create_nested_node.error': () => {
    return msg`An error has occurred while creating nested node`;
  },
  'flows.schema.delete_node.error': () => {
    return msg`An error has occurred while deleting node`;
  },
  'flows.schema.connect_nodes.error': () => {
    return msg`An error has occurred while connecting nodes`;
  },
  'flows.schema.delete_edge.error': () => {
    return msg`An error has occurred while deleting edge`;
  },
  'flows.calls.fetch.error': () => {
    return msg`An error has occurred while fetching flow calls`;
  },
  'flows.call.fetch.error': () => {
    return msg`An error has occurred while fetching flow call`;
  },
  'flows.node_call.fetch.error': () => {
    return msg`An error has occurred while fetching node call detail`;
  },
  'nodes.action.fetch.error': () => {
    return msg`An error has occurred while fetching node action`;
  },
  'nodes.action.set.error': () => {
    return msg`An error has occurred while setting node action`;
  },
  'nodes.connection.fetch.error': () => {
    return msg`An error has occurred while fetching node connection`;
  },
  'nodes.connection.set.error': () => {
    return msg`An error has occurred while setting node connection`;
  },
  'nodes.type.set.error': () => {
    return msg`An error has occurred while setting node type`;
  },
  'node_resources.fetch.error': () => {
    return msg`An error has occurred while fetching node resources`;
  },
  'organizations.fetch.error': () => {
    return msg`An error has occurred while fetching organizations`;
  },
  'organizations.leave.success': (organization: Organization) => {
    return msg`You have left ${organization.name}`;
  },
  'organizations.leave.error': (organization: Organization) => {
    return msg`An error has occurred while leaving ${organization.name}`;
  },
  // 'organizations.reset.success': () => {
  //   return msg`Organizations have been reset`;
  // },
  // 'organizations.reset.error': () => {
  //   return msg`An error has occurred while resetting organizations`;
  // },
  'projects.fetch.error': () => {
    return msg`An error has occurred while fetching projects`;
  },
  'users.fetch.error': () => {
    return msg`An error has occurred while fetching user data`;
  },
  'users.login.error': () => {
    return msg`You must have entered wrong email or password`;
  },
  'users.logout.error': () => {
    return msg`An error has occurred while logging out`;
  },
  'users.avatar.upload.success': () => {
    return msg`Profile image has been uploaded`;
  },
  'users.avatar.upload.error': () => {
    return msg`An error has occurred while uploading profile image`;
  },
  'users.avatar.remove.success': () => {
    return msg`Profile image has been removed`;
  },
  'users.avatar.remove.error': () => {
    return msg`An error has occurred while removing profile image`;
  },
  'users.data.save.success': () => {
    return msg`The changes have been saved`;
  },
  'users.data.save.error': () => {
    return msg`An error has occurred while saving data`;
  },
  'users.password.reset.success': () => {
    return msg`An email with further instructions has been sent`;
  },
  'users.password.reset.error': () => {
    return msg`An error has occurred while trying to reset password`;
  },
  'users.password.new.success': () => {
    return msg`New password has been saved`;
  },
  'users.password.new.error': () => {
    return msg`An error has occurred while setting new password`;
  },
  // 'webhooks.fetch.error': () => {
  //   return msg`An error has occurred while fetching webhooks`;
  // },
};

export default messages;
