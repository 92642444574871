import type { KeysOfType } from '@types';

export const sortByTransformedProperty = <T, P extends keyof T>(
  items: T[],
  property: P,
  reverse: boolean = false,
  transform: (propertyValue: T[P]) => number = (propertyValue: T[P]) =>
    propertyValue as number,
): T[] => {
  return items.slice().sort((a, b) => {
    const propertyA = transform(a[property]);
    const propertyB = transform(b[property]);
    const comparison = propertyA - propertyB;
    return reverse ? -comparison : comparison;
  });
};

export const sortByNumericalProperty = <T, P extends KeysOfType<T, number>>(
  items: T[],
  property: P,
  reverse: boolean = false,
): T[] => {
  return sortByTransformedProperty(items, property, reverse);
};

export const sortByDateStringProperty = <T, P extends KeysOfType<T, string>>(
  items: T[],
  property: P,
  reverse: boolean = false,
): T[] => {
  return sortByTransformedProperty(items, property, reverse, (propertyValue: T[P]) =>
    new Date(propertyValue as string).getTime(),
  );
};
