import { ForgotPasswordForm } from '@components';

import styles from './ForgotPasswordPage.module.scss';

const ForgotPasswordPage = () => {
  return (
    <div className={styles.container}>
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPasswordPage;
