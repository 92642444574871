import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { SchemaHider } from '@components';

import { API, QUERY_KEYS, ROUTES, STORAGE_KEYS } from '@config';

import {
  useActiveProject,
  useActiveProjectUuid,
  useActiveFlowUuid,
  useLocalStorage,
  useRequest,
  useDisplayResponseMessage,
  useSetActiveFlow,
  useOptionsDispatch,
  useFlows,
} from '@hooks';

import { CodeByStatusName } from '@types';

import { parseNodeCallIOData, prepareNodes } from '@utils';
import { setFlows } from '@reducers';
import { useNavigate } from 'react-router-dom';

export const useStatusNameByCode = (): {
  [code: string]: string;
} => {
  const { _ } = useLingui();
  return useMemo(
    () => ({
      [CodeByStatusName.success]: _(msg`Success`),
      [CodeByStatusName.failed]: _(msg`Failed`),
      [CodeByStatusName.retrying]: _(msg`Retrying`),
      [CodeByStatusName.in_progress]: _(msg`In progress`),
      [CodeByStatusName.in_queue]: _(msg`In queue`),
    }),
    [_],
  );
};

export const useCreateFlow = (project: Project | null) => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();
  const setActiveFlow = useSetActiveFlow();
  const navigate = useNavigate();

  return async () => {
    try {
      const { data } = await request(API.FLOWS_CREATE(project?.uuid as string), {
        method: 'POST',
      });
      const flowUuid = data as string;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOWS] });
      setActiveFlow(flowUuid);
      navigate(ROUTES.FLOW_BUILDER);
    } catch (error) {
      console.error(error);
      displayResponseMessage('flows.create.error');
    }
  };
};

export const useUpdateFlow = (activeFlow: Flow) => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const flows = useFlows();
  const activeProject = useActiveProject() as Project;
  const dispatch = useOptionsDispatch();
  const displayResponseMessage = useDisplayResponseMessage();

  return async (data: Partial<Flow>) => {
    try {
      await request(API.FLOWS_UPDATE(activeFlow.uuid), {
        method: 'PATCH',
        data,
      });
      // Optimistic update
      dispatch(
        setFlows(
          flows.map((flow: Flow) => {
            if (flow.uuid === activeFlow.uuid) {
              return { ...flow, ...data };
            } else {
              return flow;
            }
          }),
        ),
      );

      const queryKey = [QUERY_KEYS.FLOWS, activeProject.uuid];
      queryClient.invalidateQueries({ queryKey });
    } catch (error) {
      console.error(error);
      displayResponseMessage('flows.update.error');
    }
  };
};

export const useDeleteFlow = (flow: Flow) => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();

  return async () => {
    try {
      await request(API.FLOWS_DELETE(flow.uuid), {
        method: 'DELETE',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOWS] });
    } catch (error) {
      console.error(error);
      displayResponseMessage('flows.delete.error');
    }
  };
};

export const useExecuteFlow = (flow: Flow) => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();

  return async (callback: () => void) => {
    try {
      await request(API.FLOWS_EXECUTE(flow.uuid), {
        method: 'POST',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOW_CALLS] });
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        displayResponseMessage('flows.execute.error', error.response?.data?.error_text);
      }
    } finally {
      callback();
    }
  };
};

export const useFlowSchema = (flowUuid?: string | null): RawNode[] | null => {
  const request = useRequest();
  const displayResponseMessage = useDisplayResponseMessage();

  const { data } = useQuery({
    enabled: !!flowUuid && flowUuid !== 'all',
    cacheTime: 0,
    queryKey: [QUERY_KEYS.FLOW_SCHEMA, flowUuid],
    queryFn: async () => {
      try {
        const { data } = await request(API.FLOWS_RETRIEVE(flowUuid as string));
        const { nodes, relations } = data;
        return prepareNodes(nodes as RawNode[], relations);
      } catch (error) {
        console.error(error);
        displayResponseMessage('flows.schema.fetch.error');
        return null;
      }
    },
  });

  return data || null;
};

export const useFlowCalls = (
  dashboardConfiguration: DashboardConfiguration,
): {
  isLoading: boolean;
  flowCalls: FlowCall[];
  total: number;
  page: number;
  setPage: (page: number) => void;
} => {
  const request = useRequest();
  const activeProjectUuid = useActiveProjectUuid();
  const activeFlowUuid = useActiveFlowUuid();
  const displayResponseMessage = useDisplayResponseMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [flowCalls, setFlowCalls] = useState<FlowCall[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [activeFlowUuid, activeProjectUuid]);

  useQuery({
    enabled:
      !!(activeFlowUuid && activeFlowUuid !== 'all') ||
      !!(activeFlowUuid === 'all' && activeProjectUuid),
    refetchInterval: dashboardConfiguration.refreshValue,
    cacheTime: 0,
    queryKey: [QUERY_KEYS.FLOW_CALLS, activeProjectUuid, activeFlowUuid, page],
    queryFn: async () => {
      const limit = 100;
      const offset = (page - 1) * 100;

      let uuid = activeFlowUuid as string;
      let params = `flow_uuids=${uuid}&limit=${limit}&offset=${offset}&`;
      let endpoint = API.ANALYTICS_FLOWS_LOGS(params);

      if (activeFlowUuid === 'all') {
        uuid = activeProjectUuid as string;
        params = `project_uuids=${uuid}&limit=${limit}&offset=${offset}&`;
        endpoint = API.ANALYTICS_PROJECTS_LOGS(params);
      }

      setIsLoading(true);

      try {
        const { data } = await request(endpoint);
        // setTimeout for smooth transition
        setTimeout(() => {
          setIsLoading(false);
          setFlowCalls(data.logs as FlowCall[]);
          setTotal(data.total_count as number);
        }, 200);
        return data;
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        displayResponseMessage('flows.calls.fetch.error');
        return null;
      }
    },
  });

  return {
    isLoading,
    flowCalls,
    total,
    page,
    setPage,
  };
};

export const useFlowNodeCalls = (flowCallUuid?: string): NodeCall[] | null => {
  const request = useRequest();
  const displayResponseMessage = useDisplayResponseMessage();

  const { data } = useQuery({
    enabled: !!flowCallUuid,
    queryKey: [QUERY_KEYS.FLOW_CALL, flowCallUuid],
    queryFn: async () => {
      try {
        const { data } = await request(
          API.ANALYTICS_FLOWS_NODE_CALLS_MONGO(flowCallUuid as string),
        );
        return data.node_calls as NodeCall[];
      } catch (error) {
        console.error(error);
        displayResponseMessage('flows.call.fetch.error');
        return null;
      }
    },
  });

  return data || null;
};

export const useNodeCallDetail = (
  nodeCall: NodeCall,
): {
  isSuccess: boolean;
  inputData: ParsedNodeCallDataItem[];
  outputData: ParsedNodeCallDataItem[];
} => {
  const request = useRequest();
  const displayResponseMessage = useDisplayResponseMessage();

  const { isSuccess: isSuccessInput, data: inputData } = useQuery({
    queryKey: [QUERY_KEYS.NODE_CALL_INPUT_DETAIL, nodeCall.input_data],
    queryFn: async () => {
      try {
        const { data } = await request(API.MONGO_FLOW_DATA(nodeCall.input_data || []));
        return data as NodeCallDataItem[];
      } catch (error) {
        console.error(error);
        displayResponseMessage('flows.node_call.fetch.error');
        return null;
      }
    },
  });

  const { isSuccess: isSuccessOutput, data: outputData } = useQuery({
    queryKey: [QUERY_KEYS.NODE_CALL_OUTPUT_DETAIL, nodeCall.output_data],
    queryFn: async () => {
      try {
        const { data } = await request(API.MONGO_FLOW_DATA(nodeCall.output_data || []));
        return data as NodeCallDataItem[];
      } catch (error) {
        console.error(error);
        displayResponseMessage('flows.node_call.fetch.error');
        return null;
      }
    },
  });

  return {
    isSuccess: isSuccessInput && isSuccessOutput,
    inputData: inputData ? parseNodeCallIOData(inputData) : [],
    outputData: outputData ? parseNodeCallIOData(outputData) : [],
  };
};

export const useSchemaHider = () => {
  const [showSchema, setShowSchema] = useLocalStorage(STORAGE_KEYS.FLOW_SCHEMA, false);

  return {
    showSchema,
    SchemaHider: () => <SchemaHider visible={showSchema} onChange={setShowSchema} />,
  };
};
