import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Button, Avatar, Divider } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';

import { ROUTES } from '@config';

import { useUser, useLogout } from '@hooks';

import styles from './UserDropdown.module.scss';

const UserDropdown = () => {
  const [open, setOpen] = useState(false);
  const user = useUser();
  const logout = useLogout();
  const navigate = useNavigate();

  return (
    <Dropdown
      open={open}
      onOpenChange={(open: boolean) => setOpen(open)}
      trigger={['hover']}
      dropdownRender={() => (
        <div className={styles.dropdownContent}>
          <div className={styles.row}>
            <Avatar src={user.avatar} size={40} />
            <div className={styles.column}>
              <span className={styles.userName}>
                {user.first_name} {user.last_name}
              </span>
              <span className={styles.userEmail}>{user.email}</span>
            </div>
          </div>
          <Divider style={{ margin: '1.4rem 0 .8rem 0' }} />
          <Button
            className={styles.dropdownButton}
            type="text"
            icon={<UserOutlined />}
            onClick={() => {
              navigate(ROUTES.PROFILE);
              setOpen(false);
            }}
          >
            <span>
              <Trans>Manage profile</Trans>
            </span>
          </Button>
          <Button
            className={styles.dropdownButton}
            type="text"
            icon={<LogoutOutlined />}
            onClick={() => logout()}
          >
            <span>
              <Trans>Log out</Trans>
            </span>
          </Button>
        </div>
      )}
    >
      <Button shape="circle" icon={<UserOutlined />} data-testid="user-button" />
    </Dropdown>
  );
};

export default UserDropdown;
