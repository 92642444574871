import { keyBy } from 'lodash';

import { sortByNumericalProperty } from '@utils';

// This function takes in schema nodes and node calls
// and merges their data in a meaningful way.
// - Node calls in the table will gain
//   the 'name' property from the schema data
// - Nodes in the schema will gain
//   the 'status' property from the table data
export const mergeSchemaAndTableNodeCalls = (
  schemaNodes: RawNode[],
  nodeCalls: NodeCall[],
): [SchemaNodeCall[], MergedNodeCall[]] => {
  const mergedSchemaData: { [uuid: string]: SchemaNodeCall } = keyBy(
    schemaNodes,
    'uuid',
  );
  const mergedTableData: { [uuid: string]: NodeCall } = keyBy(nodeCalls, 'uuid');

  schemaNodes.forEach((nodeCall: RawNode) => {
    if (nodeCall.uuid in mergedTableData) {
      mergedTableData[nodeCall.uuid] = {
        ...mergedTableData[nodeCall.uuid],
        ...nodeCall,
      };
    }
  });

  nodeCalls.forEach((nodeCall: NodeCall) => {
    if (nodeCall.uuid in mergedSchemaData) {
      mergedSchemaData[nodeCall.uuid] = {
        ...mergedSchemaData[nodeCall.uuid],
        ...nodeCall,
      };
    }
  });

  const adjustedSchemaData = Object.values(mergedSchemaData);
  const adjustedTableData = Object.values(mergedTableData) as MergedNodeCall[];

  const sortedTableData = sortByNumericalProperty(adjustedTableData, 'depth');

  return [adjustedSchemaData, sortedTableData];
};

// This function takes in node input / output data
// as backend sends it and parses it into a usable format.
// Note that keys of NodeCallDataItem.data are of the form
// 'uuid.key' where 'uuid' is the the node call uuid
// and 'key' is some actual data key
export const parseNodeCallIOData = (
  data: NodeCallDataItem[],
): ParsedNodeCallDataItem[] => {
  const result: ParsedNodeCallDataItem[] = [];

  data.forEach((dataItem: NodeCallDataItem) => {
    const ParsedNodeCallDataItem: ParsedNodeCallDataItem = {};
    for (const dataKey in dataItem.data) {
      const key = dataKey.split('.')[1];
      ParsedNodeCallDataItem[key] = dataItem.data[dataKey];
    }
    result.push(ParsedNodeCallDataItem);
  });

  return result;
};
