const STORAGE_KEYS = {
  ANALYTICS_FREQUENCY: 'analytics-frequency',
  ANALYTICS_DATE_RANGE: 'analytics-date-range',
  ANALYTICS_OPTION: 'analytics-option',
  DASHBOARD_CONFIGURATION: 'dashboard-configuration',
  FLOW: 'flow',
  FLOW_SCHEMA: 'flow-schema-visible',
  LANGUAGE: 'language',
  ORGANIZATION: 'organization',
  PROJECT: 'project',
  SIDER: 'sider-collapsed',
};

export default STORAGE_KEYS;
