import { useCallback, useEffect, useMemo } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  Controls,
  Background,
  BezierEdge,
} from 'reactflow';
import type { NodeProps, Connection as NodeConnection } from 'reactflow';

import { BaseNode, CreateNode, DeletableEdge } from '@components';

import { useConnectNodes, useNodeResources } from '@hooks';

import { BuilderNodeType, BuilderEdgeType } from '@types';

import { getBuilderNodes, getBuilderEdges } from '@utils';

import styles from './FlowBuilder.module.scss';

type FlowBuilderProps = {
  nodes?: RawNode[] | null;
  getNodeClassName?: (node: RawNode) => string;
  editable?: boolean;
};

const FlowBuilder = ({
  nodes,
  editable = true,
  getNodeClassName,
}: FlowBuilderProps) => {
  const { nodeTypes, nodeActions } = useNodeResources();

  // const [builderNodes, setBuilderNodes] = useState<Node<NodeData>[]>([]);
  const [builderNodes, setBuilderNodes, onBuilderNodesChange] = useNodesState<NodeData>(
    [],
  );
  // const [builderEdges, setBuilderEdges] = useState<Edge[]>([]);
  const [builderEdges, setBuilderEdges, onBuilderEdgesChange] = useEdgesState<EdgeData>(
    [],
  );

  useEffect(() => {
    if (nodeTypes && nodeActions && nodes) {
      setBuilderNodes((existingBuilderNodes) => {
        return getBuilderNodes({
          nodes,
          builderNodes: existingBuilderNodes,
          editable,
          getNodeClassName,
          nodeTypes,
          nodeActions,
        });
      });
      setBuilderEdges(() =>
        getBuilderEdges({
          nodes,
          editable,
        }),
      );
    }
  }, [
    nodes,
    editable,
    getNodeClassName,
    setBuilderNodes,
    nodeTypes,
    nodeActions,
    setBuilderEdges,
  ]);

  // const onBuilderNodesChange = useCallback(
  //   (changes: NodeChange[]) => setBuilderNodes((nds) => applyNodeChanges(changes, nds)),
  //   [setBuilderNodes],
  // );
  // const onBuilderEdgesChange = useCallback(
  //   (changes: EdgeChange[]) => setBuilderEdges((eds) => applyEdgeChanges(changes, eds)),
  //   [setBuilderEdges],
  // );

  const onCloseToolbar = useCallback(
    (nodeUuid: string) => {
      setBuilderNodes((builderNodes) =>
        builderNodes.map((builderNode) => {
          if (builderNode.data.uuid === nodeUuid) {
            builderNode.selected = false;
          }
          return builderNode;
        }),
      );
    },
    [setBuilderNodes],
  );

  const onHoverEdge = (edgeId: string, hovered: boolean) => {
    setBuilderEdges((builderEdges) => {
      return builderEdges.map((edge) => {
        if (edge.id === edgeId) {
          return {
            ...edge,
            data: { ...edge.data, hovered },
          };
        } else {
          return edge;
        }
      });
    });
  };

  const connectNodes = useConnectNodes();
  const onConnect = ({ source, target }: NodeConnection) => {
    connectNodes(source as string, target as string);
  };

  const builderNodeTypes = useMemo(() => {
    return {
      [BuilderNodeType.baseNode]: (props: NodeProps<NodeData>) => (
        <BaseNode
          {...props}
          data={{
            ...props.data,
            editable,
            onCloseToolbar,
          }}
        />
      ),
      [BuilderNodeType.createNode]: CreateNode,
    };
  }, [editable, onCloseToolbar]);

  const builderEdgeTypes = useMemo(() => {
    return {
      [BuilderEdgeType.baseEdge]: BezierEdge,
      [BuilderEdgeType.deletableEdge]: DeletableEdge,
    };
  }, []);

  return (
    <div className={styles.container} data-testid="flow-builder-container">
      <ReactFlow
        nodes={builderNodes}
        edges={builderEdges}
        onNodesChange={onBuilderNodesChange}
        onEdgesChange={onBuilderEdgesChange}
        nodeTypes={builderNodeTypes}
        edgeTypes={builderEdgeTypes}
        // Add edge hovering state
        onEdgeMouseEnter={(_, edge) => onHoverEdge(edge.id, true)}
        onEdgeMouseLeave={(_, edge) => onHoverEdge(edge.id, false)}
        // Disable dragging if not editable
        nodesDraggable={editable}
        // This makes it so node toolbar doesn't get displayed when dragging
        selectNodesOnDrag={false}
        // On node connection
        onConnect={onConnect}
        // Disable edge selection
        // Disable all keyboard controls
        deleteKeyCode={null}
        selectionKeyCode={null}
        multiSelectionKeyCode={null}
        disableKeyboardA11y
        // Zoom on nodes by default
        fitView
        // Hide attribution
        proOptions={{ hideAttribution: true }}
      >
        <Controls />
        <Background gap={12} size={1} />
      </ReactFlow>
    </div>
  );
};

const FlowBuilderContainer = (props: FlowBuilderProps) => {
  return (
    <ReactFlowProvider>
      <FlowBuilder {...props} />
    </ReactFlowProvider>
  );
};

export default FlowBuilderContainer;
