import moment from 'moment';
import { useMemo } from 'react';
import { useLingui } from '@lingui/react';

import { messages } from '@locales';

import { LocalizedMoment } from '@types';

export const useLanguage = (): LanguageCode => {
  const { i18n } = useLingui();
  return i18n.locale as LanguageCode;
};

// Returns localized callable moment
export const useMoment = (): LocalizedMoment => {
  const language = useLanguage();
  const localizedMoment: LocalizedMoment = (...args) => {
    return moment(...args).locale(language) as moment.Moment;
  };
  localizedMoment.locale = language;
  return localizedMoment;
};

// This is used to return the "usual" date format
// for the current locale, e.g. MM/DD/YYYY or DD.MM.YYYY
export const useStandardDateFormat = () => {
  const moment = useMoment();
  return useMemo(() => {
    return moment().localeData().longDateFormat('L');
  }, [moment]);
};

// Returns a function that allows messages
// to be retrieved from '@locales/messages.ts' by key,
export const useGetTranslatedMessage = () => {
  const { _ } = useLingui();

  return <T extends keyof typeof messages>(
    key: T,
    ...args: Parameters<(typeof messages)[T]>
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return _(messages[key](...args));
  };
};
