import { useCallback } from 'react';

import {
  // GoBackButton,
  FlowBuilder,
  NodeCallsTable,
} from '@components';

// import { ROUTES } from '@config';

import { useFlowSchema, useFlowNodeCalls, useParamIdOrRedirect } from '@hooks';

import { scssVariables } from '@styles';

import { CodeByStatusName } from '@types';

import { mergeSchemaAndTableNodeCalls } from '@utils';

import styles from './FlowCallPage.module.scss';

const FlowCallPage = () => {
  const flowUuid = useParamIdOrRedirect('flow', true) as string;
  const flowCallUuid = useParamIdOrRedirect('flowCall', true) as string;

  const schemaNodes = useFlowSchema(flowUuid);
  const tableNodes = useFlowNodeCalls(flowCallUuid);
  const [mergedSchemaData, mergedTableData] = mergeSchemaAndTableNodeCalls(
    schemaNodes || [],
    tableNodes || [],
  );

  const getNodeClassName = useCallback((node: RawNode) => {
    switch ((node as SchemaNodeCall).status?.code) {
      case CodeByStatusName.success: {
        return 'node-success';
      }
      case CodeByStatusName.failed: {
        return 'node-failed';
      }
      case CodeByStatusName.retrying: {
        return 'node-retrying';
      }
      case CodeByStatusName.in_progress: {
        return 'node-in-progress';
      }
      case CodeByStatusName.in_queue: {
        return 'node-in-queue';
      }
      default: {
        return '';
      }
    }
  }, []);

  return (
    <div className={styles.container}>
      {/* <GoBackButton to={ROUTES.DASHBOARD} /> */}
      <div style={{ height: scssVariables.flowCallSchemaHeight }}>
        <FlowBuilder
          nodes={mergedSchemaData}
          editable={false}
          getNodeClassName={getNodeClassName}
        />
      </div>
      <NodeCallsTable nodeCalls={mergedTableData} />
    </div>
  );
};

export default FlowCallPage;
