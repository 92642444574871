import { AuthPageContainer, NoAuthPageContainer, PageContainer } from '@components';

import { ROUTES } from '@config';

import {
  AnalyticsPage,
  ConnectionsPage,
  DashboardPage,
  FlowBuilderPage,
  FlowCallPage,
  ForgotPasswordPage,
  IndexPage,
  LoginPage,
  NotFoundPage,
  ProfilePage,
  ResetPasswordPage,
  SentryErrorPage,
  // WebhookDetailPage,
  // WebhooksPage,
} from '@pages';

export const APP_ROUTES = [
  // Just functions / redirects
  {
    path: ROUTES.INDEX,
    element: <IndexPage />,
  },
  // Need to be logged out
  {
    path: ROUTES.LOGIN,
    element: (
      <NoAuthPageContainer>
        <LoginPage />
      </NoAuthPageContainer>
    ),
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: (
      <NoAuthPageContainer>
        <ForgotPasswordPage />
      </NoAuthPageContainer>
    ),
  },
  // Need to be logged in
  {
    path: ROUTES.DASHBOARD,
    element: (
      <AuthPageContainer>
        <DashboardPage />
      </AuthPageContainer>
    ),
  },
  {
    path: ROUTES.FLOW_BUILDER,
    element: (
      <AuthPageContainer>
        <FlowBuilderPage />
      </AuthPageContainer>
    ),
  },
  {
    path: `${ROUTES.FLOW}/:flow${ROUTES.FLOW_CALL}/:flowCall`,
    element: (
      <AuthPageContainer>
        <FlowCallPage />
      </AuthPageContainer>
    ),
  },
  {
    path: ROUTES.CONNECTIONS,
    element: (
      <AuthPageContainer>
        <ConnectionsPage />
      </AuthPageContainer>
    ),
  },
  {
    path: ROUTES.PROFILE,
    element: (
      <AuthPageContainer>
        <ProfilePage />
      </AuthPageContainer>
    ),
  },
  // {
  //   path: ROUTES.WEBHOOKS,
  //   element: (
  //     <AuthPageContainer>
  //       <WebhooksPage />
  //     </AuthPageContainer>
  //   ),
  // },
  // {
  //   path: `${ROUTES.WEBHOOKS}/:webhook`,
  //   element: (
  //     <AuthPageContainer>
  //       <WebhookDetailPage />
  //     </AuthPageContainer>
  //   ),
  // },
  {
    path: ROUTES.ANALYTICS,
    element: (
      <AuthPageContainer>
        <AnalyticsPage />
      </AuthPageContainer>
    ),
  },
  // Can be both logged in / out
  {
    path: ROUTES.RESET_PASSWORD,
    element: (
      <PageContainer noLayout>
        <ResetPasswordPage />
      </PageContainer>
    ),
  },
  {
    path: ROUTES.SENTRY_ERROR,
    element: (
      <PageContainer noLayout>
        <SentryErrorPage />
      </PageContainer>
    ),
  },
  {
    path: '*',
    element: (
      <PageContainer noLayout>
        <NotFoundPage />
      </PageContainer>
    ),
  },
];
