import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Input, Button } from 'antd';
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ReactComponent as Logo } from '@assets/logo_barevne.svg';

import { ROUTES } from '@config';

import { useLogin } from '@hooks';

import styles from './LoginForm.module.scss';

const LoginForm = () => {
  const { _ } = useLingui();

  const email = useRef('');
  const password = useRef('');

  const login = useLogin();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    login({ email: email.current, password: password.current });
  };

  return (
    <form className={styles.formContainer} onSubmit={onSubmit}>
      {/* <div className={styles.title}>Symmy</div> */}
      <Logo className={styles.logo} />
      <Input
        className={styles.input}
        prefix={<UserOutlined />}
        size="large"
        type="email"
        name="email"
        onChange={(event) => (email.current = event.target.value)}
        placeholder="Email"
        required
        data-testid="email-input"
      />
      <Input
        className={styles.input}
        prefix={<LockOutlined />}
        size="large"
        type="password"
        name="password"
        onChange={(event) => (password.current = event.target.value)}
        placeholder={_(msg`Password`)}
        required
        minLength={8}
        data-testid="password-input"
      />
      <Button
        className={`${styles.button} button-theme2`}
        type="primary"
        shape="round"
        icon={<LoginOutlined />}
        htmlType="submit"
        data-testid="login-button"
      >
        <span>
          <Trans>Log in</Trans>
        </span>
      </Button>
      <Link to={ROUTES.FORGOT_PASSWORD}>
        <div className={styles.forgotPassword}>
          <Trans>Forgot password?</Trans>
        </div>
      </Link>
    </form>
  );
};

export default LoginForm;
