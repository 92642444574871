import { useState } from 'react';
import { Card, Button } from 'antd';
import { Trans } from '@lingui/macro';

import { useResetPasswordAuth } from '@hooks';

const Security = () => {
  const resetPassword = useResetPasswordAuth();
  const [emailSent, setEmailSent] = useState(false);

  const onButtonClick = async () => {
    const success = await resetPassword();
    if (success) setEmailSent(true);
  };

  return (
    <Card
      title={
        <span>
          <Trans>Password reset</Trans>
        </span>
      }
    >
      <p>
        <Trans>
          To reset your password, click the button below and wait for an email with
          further instructions.
        </Trans>
      </p>
      <Button
        disabled={emailSent}
        type="primary"
        shape="round"
        onClick={() => onButtonClick()}
        data-testid="reset-password-button"
      >
        <Trans>Reset password</Trans>
      </Button>
    </Card>
  );
};

export default Security;
