import { App as AntdApp, ConfigProvider } from 'antd';
import csCZ from 'antd/locale/cs_CZ';
import enUS from 'antd/locale/en_US';

import { THEME } from '@config';

import { useLanguage } from '@hooks';

const WithAntdConfig = ({ children }: React.PropsWithChildren) => {
  const language = useLanguage();

  let locale;
  switch (language) {
    case 'cs': {
      locale = csCZ;
      break;
    }
    case 'en': {
      locale = enUS;
      break;
    }
    default: {
      locale = enUS;
      break;
    }
  }

  return (
    <ConfigProvider locale={locale} theme={THEME}>
      <AntdApp>{children}</AntdApp>
    </ConfigProvider>
  );
};

WithAntdConfig.THEME = THEME;

export default WithAntdConfig;
