import { Select, type SelectProps } from 'antd';

const SearchableSelect = <T,>(props: SelectProps<T, OptionLabelValue>) => {
  return (
    <Select
      popupMatchSelectWidth={false}
      showSearch={true}
      filterOption={(input: string, option?: OptionLabelValue) => {
        return (option?.label ?? '')
          .toString()
          .toLowerCase()
          .includes(input.toLowerCase());
      }}
      {...props}
    />
  );
};

export default SearchableSelect;
