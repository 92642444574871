import { APP_ROUTES } from '@app';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const AppRouter = () => {
  const router = createBrowserRouter(APP_ROUTES);

  return <RouterProvider router={router} />;
};

export default AppRouter;
