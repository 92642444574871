import { ResetPasswordForm } from '@components';

import styles from './ResetPasswordPage.module.scss';

const ResetPasswordPage = () => {
  return (
    <div className={styles.container}>
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPasswordPage;
