import { Trans } from '@lingui/macro';

import { FlowBuilder, FlowConfiguration, FlowExecutionButton } from '@components';

import { useActiveFlowUuid, useFlowSchema } from '@hooks';

import styles from './FlowBuilderPage.module.scss';

const FlowBuilderPage = () => {
  const activeFlowUuid = useActiveFlowUuid();
  const nodes = useFlowSchema(activeFlowUuid);

  return (
    <div className={styles.container}>
      {activeFlowUuid === 'all' ? (
        <span className={styles.selectFlowText}>
          <Trans>Select a flow to get started</Trans>
        </span>
      ) : (
        <>
          {/*
            Rendering this when there is no active flow makes
            transitions smoother, using key like this also makes
            the transitions smooth when switching flows
          */}
          <FlowBuilder key={activeFlowUuid} nodes={nodes} />
          {activeFlowUuid && <FlowConfiguration />}
          {activeFlowUuid && <FlowExecutionButton />}
        </>
      )}
    </div>
  );
};

export default FlowBuilderPage;
