import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ShowHideButton } from '@components';

import styles from './SchemaHider.module.scss';

type SchemaHiderProps = {
  visible: boolean;
  onChange: () => void;
};

const SchemaHider = ({ visible, onChange }: SchemaHiderProps) => {
  const { _ } = useLingui();

  return (
    <div className={styles.container}>
      <ShowHideButton
        visible={visible}
        onChange={onChange}
        showText={_(msg`View flow schema`)}
        hideText={_(msg`Hide flow schema`)}
      />
    </div>
  );
};

export default SchemaHider;
