import { Fragment, useTransition } from 'react';
import { Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { SearchableSelect } from '@components';

import {
  useOptions,
  useSetActiveFlow,
  useSetActiveOrganization,
  useSetActiveProject,
  useCreateFlow,
} from '@hooks';

import { getSelectOptions } from '@utils';

import styles from './HeaderSelects.module.scss';

const HeaderSelects = () => {
  const { _ } = useLingui();
  const [, startTransition] = useTransition();
  const {
    organizations,
    activeOrganization,
    projects,
    activeProject,
    flows,
    activeFlow,
  } = useOptions();
  const setActiveOrganization = useSetActiveOrganization();
  const setActiveProject = useSetActiveProject();
  const setActiveFlow = useSetActiveFlow();
  const createFlow = useCreateFlow(activeProject);

  return (
    <Fragment>
      <SearchableSelect
        className={styles.select}
        options={getSelectOptions(organizations)}
        value={activeOrganization?.uuid}
        onChange={(organizationUuid: string) => {
          startTransition(() => {
            setActiveOrganization(organizationUuid);
          });
        }}
        data-testid="organization-select"
        dropdownRender={(OriginDropdown) => (
          <div data-testid="organization-select-dropdown">{OriginDropdown}</div>
        )}
      />
      <SearchableSelect
        className={styles.select}
        options={getSelectOptions(projects)}
        value={activeProject?.uuid}
        onChange={(projectUuid) => {
          startTransition(() => {
            setActiveProject(projectUuid);
          });
        }}
        data-testid="project-select"
        dropdownRender={(OriginDropdown) => (
          <div data-testid="project-select-dropdown">{OriginDropdown}</div>
        )}
      />
      <SearchableSelect
        className={styles.select}
        options={[
          { label: _(msg`All flows`), value: 'all' },
          ...getSelectOptions(flows),
        ]}
        value={activeFlow === 'all' ? activeFlow : activeFlow?.uuid}
        onChange={(flowUuid) => {
          startTransition(() => {
            setActiveFlow(flowUuid);
          });
        }}
        data-testid="flow-select"
        dropdownRender={(OriginDropdown) => (
          <div data-testid="flow-select-dropdown">{OriginDropdown}</div>
        )}
      />
      <Tooltip title={activeProject ? _(msg`Create flow`) : ''}>
        <Button
          disabled={!activeProject}
          className={`${styles.createFlowButton}`}
          draggable={false}
          type="primary"
          size="small"
          shape="circle"
          icon={<PlusOutlined className={styles.plusIcon} />}
          onClick={() => {
            startTransition(() => {
              createFlow();
            });
          }}
        />
      </Tooltip>
    </Fragment>
  );
};

export default HeaderSelects;
