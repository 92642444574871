const API = {
  // Analytics
  ANALYTICS_FLOWS_LOGS: (params: string) => {
    const endpoint = '/api/v1/analytics/flows/logs/';
    const baseParams = `?bulk_result=True&exclude_nodes=True&`;
    return endpoint + baseParams + params;
  },
  ANALYTICS_FLOWS_NODE_CALLS: (params: string) => {
    const endpoint = '/api/v1/analytics/flows/node-calls/';
    const baseParams = `?bulk_result=True&`;
    return endpoint + baseParams + params;
  },
  ANALYTICS_FLOWS_NODE_CALLS_MONGO: (flowCallUuid: string) =>
    `/api/v1/analytics/flows/node-calls/?mongo_flow_ids=${flowCallUuid}&bulk_result=True`,
  ANALYTICS_ORGANIZATIONS_LOGS: (params: string) => {
    const endpoint = '/api/v1/analytics/organizations/logs/';
    const baseParams = `?bulk_result=True&exclude_nodes=True&`;
    return endpoint + baseParams + params;
  },
  ANALYTICS_ORGANIZATIONS_NODE_CALLS: (params: string) => {
    const endpoint = '/api/v1/analytics/organizations/node-calls/';
    const baseParams = `?bulk_result=True&`;
    return endpoint + baseParams + params;
  },
  ANALYTICS_PROJECTS_LOGS: (params: string) => {
    const endpoint = '/api/v1/analytics/projects/logs/';
    const baseParams = `?bulk_result=True&exclude_nodes=True&`;
    return endpoint + baseParams + params;
  },
  ANALYTICS_PROJECTS_NODE_CALLS: (params: string) => {
    const endpoint = '/api/v1/analytics/projects/node-calls/';
    const baseParams = `?bulk_result=True&`;
    return endpoint + baseParams + params;
  },

  // Connections
  CONNECTIONS_CREATE: () => `/api/v1/connections/create/`,
  CONNECTIONS_DELETE: (connectionUuid: string) =>
    `/api/v1/connections/delete/${connectionUuid}/`,
  // CONNECTIONS_DISCONNECT: (connection: number) =>
  //   `/api/v1/connections/${connection}/disconnect/`,
  CONNECTIONS_NODETYPE_LIST: (nodeTypeUuid: string, organizationUuid: string) =>
    `/api/v1/connections/nodetype/${nodeTypeUuid}/organization/${organizationUuid}/list/`,
  // CONNECTIONS_VERIFY: (connection: number) =>
  //   `/api/v1/connections/${connection}/verify/`,

  // Core
  CORE_APP_INFO: () => '/api/v1/core/app-info/',
  CORE_ORGANIZATIONS_CONNECTIONS: (organizationUuid: string) =>
    `/api/v1/core/organizations/retrieve-connections/${organizationUuid}/`,
  CORE_ORGANIZATIONS_LEAVE: (organizationUuid: string) =>
    `/api/v1/core/organizations/${organizationUuid}/leave/`,
  CORE_ORGANIZATIONS_LIST: () => '/api/v1/core/organizations/list/',
  CORE_PROJECTS_LIST: (organizationUuid: string) =>
    `/api/v1/core/projects/list/?organization_uuids=${organizationUuid}`,

  // Flows
  FLOWS_BUILDER_CONNECT_NODES: (sourceNodeUuid: string, targetNodeUuid: string) =>
    `/api/v1/flows/node/connect/${sourceNodeUuid}/${targetNodeUuid}/`,
  FLOWS_BUILDER_CREATE_NESTED_NODE: (parentNodeUuid: string) =>
    `/api/v1/flows/node/create-nested-node/${parentNodeUuid}/`,
  FLOWS_BUILDER_CREATE_ROOT_NODE: (flowUuid: string) =>
    `/api/v1/flows/create-root-node/${flowUuid}/`,
  FLOWS_BUILDER_DELETE_EDGE: (sourceNodeUuid: string, targetNodeUuid: string) =>
    `/api/v1/flows/node/disconnect/${sourceNodeUuid}/${targetNodeUuid}/`,
  FLOWS_BUILDER_DELETE_NODE: (nodeUuid: string) =>
    `/api/v1/flows/node/delete/${nodeUuid}/`,
  FLOWS_BUILDER_NODE_RESOURCES: () => `/api/v1/flows/node/resources/`,
  FLOWS_BUILDER_SET_NODE_ACTION: (nodeUuid: string, nodeActionUuid: string) =>
    `/api/v1/flows/node/set-action/${nodeUuid}/${nodeActionUuid}/`,
  FLOWS_BUILDER_SET_NODE_CONNECTION: (nodeUuid: string, nodeConnectionUuid: string) =>
    `/api/v1/flows/builder/node/set-connection/${nodeUuid}/${nodeConnectionUuid}/`,
  FLOWS_BUILDER_SET_NODE_TYPE: (nodeUuid: string, nodeTypeUuid: string) =>
    `/api/v1/flows/node/set-type/${nodeUuid}/${nodeTypeUuid}/`,
  FLOWS_CREATE: (projectUuid: string) =>
    `/api/v1/core/projects/create-flow/${projectUuid}/`,
  FLOWS_DELETE: (flowUuid: string) => `/api/v1/flows/delete/${flowUuid}/`,
  FLOWS_EXECUTE: (flowUuid: string) => `/api/v1/flows/execute/${flowUuid}/`,
  FLOWS_LIST: (projectUuid: string) =>
    `/api/v1/flows/list/?project_uuids=${projectUuid}`,
  FLOWS_RETRIEVE: (flowUuid: string) => `/api/v1/flows/retrieve/${flowUuid}/`,
  FLOWS_UPDATE: (flowUuid: string) => `/api/v1/flows/update/${flowUuid}/`,

  // Mongo
  MONGO_FLOW_DATA: (fileIds: string[]) =>
    `/api/v1/mongo/flow-data/?${fileIds
      .map((fileId: string) => `file_ids=${fileId}&`)
      .join('')}`,

  // Nodetypes
  NODETYPES_ACTIONS_INPUT: (nodeActionUuid: string) =>
    `/api/v1/nodetypes/actions/${nodeActionUuid}/input/`,
  NODETYPES_ACTIONS_OUTPUT: (nodeActionUuid: string) =>
    `/api/v1/nodetypes/actions/${nodeActionUuid}/output/`,
  NODETYPES_CONNECTION: (nodeTypeUuid: string) =>
    `/api/v1/nodetypes/nodetypes/${nodeTypeUuid}/connection/`,

  // Users
  USERS_AUTHENTICATE: () => '/api/v1/users/authenticate/',
  USERS_CHANGE_PASSWORD: () => '/api/v1/users/change-password/',
  USERS_FORGOT_PASSWORD: () => '/api/v1/users/forgot-password/',
  USERS_LOGOUT: () => '/api/v1/users/logout/',
  USERS_REMOVE_AVATAR: () => '/api/v1/users/remove-avatar/',
  USERS_RESET_PASSWORD: (uuid: string, token: string) =>
    `/api/v1/users/reset-password/${uuid}/${token}/`,
  USERS_UPDATE_AVATAR: () => '/api/v1/users/update-avatar/',
  USERS_UPDATE_DATA: () => '/api/v1/users/update-data/',
  USERS_USER: () => '/api/v1/users/user/',

  // // Webhooks
  // WEBHOOKS: (organizationUuid: string) =>
  //   `/api/v1/organizations/${organizationUuid}/webhooks/`,
  // WEBHOOKS_ACTIVATE: (webhookUuid: string) =>
  //   `/api/v1/webhooks/${webhookUuid}/activate/`,
  // WEBHOOKS_DETAIL: (webhookUuid: string) => `/api/v1/webhooks/${webhookUuid}/`,
};

export default API;
