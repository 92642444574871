import { MouseEvent, memo, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Handle, Position, NodeToolbar } from 'reactflow';
import type { NodeProps } from 'reactflow';
import { Tooltip, Button } from 'antd';
import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { NodeConfiguration } from '@components';

import { useCreateNestedNode, useDeleteNode, useEventListener } from '@hooks';

import styles from './BaseNode.module.scss';

const BaseNode = ({ data, selected }: NodeProps<NodeData>) => {
  const { _ } = useLingui();

  const createNestedNode = useCreateNestedNode(data);
  const deleteNode = useDeleteNode(data);

  const containerRef = useRef(null);
  const [isHoveringContainer, setIsHoveringContainer] = useState(false);
  useEventListener('mouseenter', () => setIsHoveringContainer(true), containerRef);
  useEventListener('mouseleave', () => setIsHoveringContainer(false), containerRef);

  const nodeAnimationStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <animated.div ref={containerRef} style={nodeAnimationStyle}>
      <Handle
        className={styles.handle}
        type="target"
        position={Position.Left}
        isConnectable={!data.parent}
        // style={{ visibility: data.parent ? 'visible' : 'hidden' }}
      />
      <Handle
        className={styles.handle}
        type="source"
        position={Position.Right}
        isConnectable={true}
        // style={{ visibility: data.children.length > 0 ? 'visible' : 'hidden' }}
      />
      <div
        className={`base-node-drag-handle ${styles.content} ${
          isHoveringContainer || selected ? styles.contentHover : ''
        } ${data.className || ''}`}
      >
        <div className={styles.nodeType}>
          {data.nodeType?.name || 'Select node type'}
        </div>
        <div className={styles.nodeAction}>
          {data.nodeAction?.name && `${data.nodeAction?.name}`}
        </div>
      </div>
      {data.editable && (
        <Tooltip title={_(msg`Add nested node`)}>
          <Button
            className={`${styles.addButton} ${
              isHoveringContainer ? styles.addButtonVisible : ''
            }`}
            draggable={false}
            type="primary"
            size="small"
            shape="circle"
            icon={<PlusOutlined className={styles.plusIcon} />}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              createNestedNode();
            }}
          />
        </Tooltip>
      )}
      {data.editable && (
        <NodeToolbar
          className={`nodrag nopan nowheel ${styles.nodeToolbarContainer} ${
            selected ? styles.nodeToolbarContainerVisible : ''
          }`}
          position={Position.Right}
          isVisible={selected}
        >
          <div className={styles.nodeToolbar}>
            <div className={styles.nodeToolbarArrow} />
            <div className={styles.nodeToolbarRow}>
              <div>
                <Trans>Configure node type</Trans>
              </div>
              <Button
                className={styles.closeButton}
                type="text"
                size="small"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => data.onCloseToolbar?.(data.uuid)}
              />
            </div>
            <NodeConfiguration data={data} />
            <div>
              <Button
                className={styles.deleteButton}
                size="small"
                shape="round"
                icon={<DeleteOutlined />}
                onClick={() => deleteNode()}
              >
                <span>
                  <Trans>Delete</Trans>
                </span>
              </Button>
            </div>
          </div>
        </NodeToolbar>
      )}
    </animated.div>
  );
};

const MemoizedBaseNode = memo(BaseNode);
export default MemoizedBaseNode;
