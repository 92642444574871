import { Spin } from 'antd';
import { Trans } from '@lingui/macro';

import { ConnectionItem } from '@components';

import { useActiveOrganization, useConnections } from '@hooks';

import styles from './ConnectionsPage.module.scss';

const ConnectionsPage = () => {
  const activeOrganization = useActiveOrganization();
  const { isSuccess, data } = useConnections(activeOrganization);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.topRow}>
          <h1 className={styles.title}>
            <Trans>Connections</Trans>
          </h1>
        </div>
        {!isSuccess && (
          <div className={styles.spinner}>
            <Spin />
          </div>
        )}
        {isSuccess &&
          (data.length === 0 ? (
            <p>
              <Trans>No connections are currently available</Trans>
            </p>
          ) : (
            data.map((connection: Connection) => (
              <ConnectionItem key={connection.uuid} connection={connection} />
            ))
          ))}
      </div>
    </div>
  );
};

export default ConnectionsPage;
