import { useEffect } from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import { STORAGE_KEYS } from '@config';

import { useUserState } from '@hooks';

// Do this to prevent console warning
// (https://github.com/lingui/js-lingui/issues/1194#issuecomment-1068488619)
const language = localStorage.getItem(STORAGE_KEYS.LANGUAGE) || 'en';
i18n.load(language, {});
i18n.activate(language);

const dynamicActivate = async (language: string) => {
  const { messages } = await import(`../locales/${language}.po`);
  i18n.load(language, messages);
  i18n.activate(language);
};

const WithLingui = ({ children }: React.PropsWithChildren) => {
  const userState = useUserState();

  useEffect(() => {
    dynamicActivate(userState.language);
  }, [userState.language]);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default WithLingui;
