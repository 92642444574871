import { useMemo } from 'react';
import { useNodes, type Node } from 'reactflow';
import {
  // useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { API, QUERY_KEYS } from '@config';

import {
  useRequest,
  useDisplayResponseMessage,
  // useActiveOrganization,
} from '@hooks';

export const useCreateRootNode = (flow: Flow) => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();

  return async () => {
    try {
      await request(API.FLOWS_BUILDER_CREATE_ROOT_NODE(flow.uuid), {
        method: 'POST',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOW_SCHEMA] });
    } catch (error) {
      console.error(error);
      displayResponseMessage('flows.schema.create_root_node.error');
    }
  };
};

export const useCreateNestedNode = (parentNode: NodeData) => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();

  return async () => {
    try {
      await request(API.FLOWS_BUILDER_CREATE_NESTED_NODE(parentNode.uuid), {
        method: 'POST',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOW_SCHEMA] });
    } catch (error) {
      console.error(error);
      displayResponseMessage('flows.schema.create_nested_node.error');
    }
  };
};

export const useDeleteNode = (node: NodeData) => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();

  return async () => {
    try {
      await request(API.FLOWS_BUILDER_DELETE_NODE(node.uuid), {
        method: 'DELETE',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOW_SCHEMA] });
    } catch (error) {
      console.error(error);
      displayResponseMessage('flows.schema.delete_node.error');
    }
  };
};

export const useConnectNodes = () => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();

  return async (sourceNodeUuid: string, targetNodeUuid: string) => {
    try {
      await request(API.FLOWS_BUILDER_CONNECT_NODES(sourceNodeUuid, targetNodeUuid), {
        method: 'POST',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOW_SCHEMA] });
    } catch (error) {
      console.error(error);
      displayResponseMessage('flows.schema.connect_nodes.error');
    }
  };
};

export const useDeleteEdge = () => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();

  return async (sourceNodeUuid: string, targetNodeUuid: string) => {
    try {
      await request(API.FLOWS_BUILDER_DELETE_EDGE(sourceNodeUuid, targetNodeUuid), {
        method: 'POST',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOW_SCHEMA] });
    } catch (error) {
      console.error(error);
      displayResponseMessage('flows.schema.delete_edge.error');
    }
  };
};

export const useNodeResources = (): {
  nodeTypes?: NodeType[];
  nodeActions?: NodeAction[];
} => {
  const request = useRequest();
  const displayResponseMessage = useDisplayResponseMessage();

  const { data } = useQuery({
    queryKey: [QUERY_KEYS.NODE_RESOURCES],
    queryFn: async () => {
      try {
        const { data } = await request(API.FLOWS_BUILDER_NODE_RESOURCES());
        return data as {
          node_type_resources: NodeType[];
          action_resources: NodeAction[];
        };
      } catch (error) {
        console.error(error);
        displayResponseMessage('node_resources.fetch.error');
        return null;
      }
    },
    staleTime: Infinity,
  });

  return {
    nodeTypes: data?.node_type_resources,
    nodeActions: data?.action_resources,
  };
};

export const useSetNodeType = (node: NodeData) => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();

  return async (nodeTypeUuid: string) => {
    try {
      await request(API.FLOWS_BUILDER_SET_NODE_TYPE(node.uuid, nodeTypeUuid), {
        method: 'PATCH',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOW_SCHEMA] });
    } catch (error) {
      console.error(error);
      displayResponseMessage('nodes.type.set.error');
    }
  };
};

export const useSetNodeAction = (node: NodeData) => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();

  return async (nodeActionUuid: string) => {
    try {
      await request(API.FLOWS_BUILDER_SET_NODE_ACTION(node.uuid, nodeActionUuid), {
        method: 'PATCH',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOW_SCHEMA] });
    } catch (error) {
      console.error(error);
      displayResponseMessage('nodes.action.set.error');
    }
  };
};

// export const useNodeConnectionOptions = (nodeType?: NodeType) => {
//   const request = useRequest();
//   const displayResponseMessage = useDisplayResponseMessage();
//   const organization = useActiveOrganization();

//   const { data } = useQuery({
//     enabled: !!(nodeType && organization?.uuid),
//     queryKey: [
//       QUERY_KEYS.CONNECTIONS,
//       QUERY_KEYS.NODE_CONNECTION_OPTIONS,
//       nodeType?.uuid,
//       organization?.uuid,
//     ],
//     queryFn: async () => {
//       try {
//         const { data } = await request(
//           API.CONNECTIONS_NODETYPE_LIST(
//             nodeType?.uuid as string,
//             organization?.uuid as string,
//           ),
//         );
//         return data as Connection[];
//       } catch (error) {
//         console.error(error);
//         displayResponseMessage('nodes.connection.fetch.error');
//         return null;
//       }
//     },
//     staleTime: Infinity,
//   });

//   return data;
// };

// export const useSetNodeConnection = (node: NodeData) => {
//   const request = useRequest();
//   const queryClient = useQueryClient();
//   const displayResponseMessage = useDisplayResponseMessage();

//   return async (nodeConnectionUuid: string) => {
//     try {
//       await request(
//         API.FLOWS_BUILDER_SET_NODE_CONNECTION(node.uuid, nodeConnectionUuid),
//         {
//           method: 'POST',
//         },
//       );
//       queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FLOW_SCHEMA] });
//     } catch (error) {
//       console.error(error);
//       displayResponseMessage('nodes.connection.set.error');
//     }
//   };
// };

// export const useNodeConnectionInputFields = (
//   nodeType?: NodeType
// ): FieldConfiguration[] => {
//   const request = useRequest();
//   const displayResponseMessage = useDisplayResponseMessage();

//   const { data } = useQuery({
//     enabled: !!nodeType,
//     queryKey: [QUERY_KEYS.NODE_CONNECTION_INPUT_FIELDS, nodeType?.uuid],
//     queryFn: async () => {
//       try {
//         const { data } = await request(
//           API.NODETYPES_CONNECTION(nodeType?.uuid as string)
//         );
//         return data.fields as FieldConfiguration[];
//       } catch (error) {
//         console.error(error);
//         displayResponseMessage('nodes.connection.fetch.error');
//         return null;
//       }
//     },
//     staleTime: Infinity,
//   });

//   return data || [];
// };

// export const useNodeActionInputFields = (
//   nodeAction?: NodeAction,
// ): FieldConfiguration[] => {
//   const request = useRequest();
//   const displayResponseMessage = useDisplayResponseMessage();

//   const { data } = useQuery({
//     enabled: !!nodeAction,
//     queryKey: [QUERY_KEYS.NODE_ACTION_INPUT_FIELDS, nodeAction?.uuid],
//     queryFn: async () => {
//       try {
//         const { data } = await request(
//           API.NODETYPES_ACTIONS_INPUT(nodeAction?.uuid as string),
//         );
//         return data.fields as FieldConfiguration[];
//       } catch (error) {
//         console.error(error);
//         displayResponseMessage('nodes.action.fetch.error');
//         return null;
//       }
//     },
//     staleTime: Infinity,
//   });

//   return data || [];
// };

export const useSelectedNode = (): Node<NodeData> | null => {
  const nodes = useNodes<NodeData>();
  return (
    useMemo(() => {
      return nodes.find((node) => node.selected === true);
    }, [nodes]) || null
  );
};

// export const useOutputFieldsMap = (): Map<Node<NodeData>, FieldConfiguration[]> => {
//   const request = useRequest();
//   const displayResponseMessage = useDisplayResponseMessage();

//   const nodes = useNodes<NodeData>();
//   const selectedNode = useSelectedNode();

//   // To get relevant output fields, we need to fetch output fields
//   // of all parent nodes that have an action, these are the 'relevantNodes'
//   const relevantNodes = useMemo(() => {
//     if (!selectedNode) return [];
//     const _relevantNodes = [];
//     let currentNode: Node<NodeData> | null = selectedNode;
//     while (currentNode?.data.parent) {
//       const parentNode =
//         nodes.find((node) => node.data.uuid === currentNode?.data.parent) || null;
//       if (parentNode?.data.nodeAction) {
//         _relevantNodes.push(parentNode);
//       }
//       currentNode = parentNode;
//     }
//     return _relevantNodes;
//   }, [nodes, selectedNode]);

//   // Construct output field query for each relevant parent node
//   const queries = useMemo(() => {
//     return relevantNodes.map((node) => {
//       const action = node.data.nodeAction as NodeAction;
//       return {
//         queryKey: [QUERY_KEYS.NODE_ACTION_OUTPUT_FIELDS, action.uuid],
//         queryFn: async () => {
//           try {
//             const { data } = await request(API.NODETYPES_ACTIONS_OUTPUT(action.uuid));
//             return data.fields;
//           } catch (error) {
//             console.error(error);
//             displayResponseMessage('nodes.action.fetch.error');
//             return null;
//           }
//         },
//         staleTime: Infinity,
//       };
//     });
//   }, [request, displayResponseMessage, relevantNodes]);

//   const results = useQueries({
//     queries,
//   });

//   // Construct output fields map, use Map to ensure consistent key order
//   const outputFieldsMap = useMemo(() => {
//     const _outputFieldsMap = new Map<Node<NodeData>, FieldConfiguration[]>();
//     results.forEach((result, index) => {
//       const node = relevantNodes[index];
//       if (result.isSuccess && result.data.length > 0) {
//         _outputFieldsMap.set(node, result.data as FieldConfiguration[]);
//       }
//     });
//     return _outputFieldsMap;
//   }, [relevantNodes, results]);

//   return outputFieldsMap;
// };
