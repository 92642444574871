import { AxiosError } from 'axios';
import { useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { API, COOKIES, QUERY_KEYS, ROUTES } from '@config';

import { useRequest, useDisplayResponseMessage } from '@hooks';

import { UserContext, setUser, setLanguage } from '@reducers';

import { setCookie } from '@utils';

export const useUserContext = () => {
  const context = useContext(UserContext);
  return context;
};

export const useUserState = () => {
  const { state } = useContext(UserContext);
  return state;
};

export const useUserDispatch = () => {
  const { dispatch } = useContext(UserContext);
  return dispatch;
};

export const useUser = () => {
  return useUserState().user as User;
};

export const useFetchUserData = () => {
  const request = useRequest();
  const dispatch = useUserDispatch();
  const displayResponseMessage = useDisplayResponseMessage();

  useQuery({
    queryKey: [QUERY_KEYS.USER_DATA],
    queryFn: async () => {
      try {
        const { data } = await request(API.USERS_USER());
        dispatch(setUser(data as User));
        return data as User;
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error?.response?.status === 401) {
            dispatch(setUser(null));
          } else {
            displayResponseMessage('users.fetch.error');
          }
        }
        return null;
      }
    },
  });
};

export const useRefetchUser = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_DATA] });
  };
};

export const useSetLanguage = () => {
  const dispatch = useUserDispatch();
  return (language: LanguageCode) => {
    dispatch(setLanguage(language));
  };
};

export const useLogin = () => {
  const request = useRequest();
  const refetchUser = useRefetchUser();
  const displayResponseMessage = useDisplayResponseMessage();

  return async ({ email, password }: { email: string; password: string }) => {
    try {
      const { data } = await request(API.USERS_AUTHENTICATE(), {
        method: 'POST',
        data: {
          email: email,
          password: password,
        },
      });
      const token = data.token as string;
      setCookie(COOKIES.AUTH_TOKEN, token);
      refetchUser();
    } catch (error) {
      console.error(error);
      displayResponseMessage('users.login.error');
    }
  };
};

export const useLogout = () => {
  const request = useRequest();
  const refetchUser = useRefetchUser();
  const displayResponseMessage = useDisplayResponseMessage();

  return async () => {
    try {
      await request(API.USERS_LOGOUT(), {
        method: 'POST',
      });
      setCookie(COOKIES.AUTH_TOKEN, '');
      refetchUser();
    } catch (error) {
      console.error(error);
      displayResponseMessage('users.logout.error');
    }
  };
};

export const useUploadAvatar = () => {
  const request = useRequest();
  const refetchUser = useRefetchUser();
  const displayResponseMessage = useDisplayResponseMessage();

  return async (file: File) => {
    const formData = new FormData();
    formData.append('avatar', file);

    try {
      await request(API.USERS_UPDATE_AVATAR(), {
        method: 'PATCH',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      refetchUser();
      displayResponseMessage('users.avatar.upload.success');
    } catch (error) {
      console.error(error);
      displayResponseMessage('users.avatar.upload.error');
    }
  };
};

export const useRemoveAvatar = () => {
  const request = useRequest();
  const refetchUser = useRefetchUser();
  const displayResponseMessage = useDisplayResponseMessage();

  return async () => {
    try {
      await request(API.USERS_REMOVE_AVATAR(), {
        method: 'DELETE',
      });
      refetchUser();
      displayResponseMessage('users.avatar.remove.success');
    } catch (error) {
      console.error(error);
      displayResponseMessage('users.avatar.remove.error');
    }
  };
};

export const useSaveUserData = () => {
  const request = useRequest();
  const refetchUser = useRefetchUser();
  const displayResponseMessage = useDisplayResponseMessage();

  return async (data: { [key: string]: string }) => {
    try {
      await request(API.USERS_UPDATE_DATA(), {
        method: 'PATCH',
        data,
      });
      refetchUser();
      displayResponseMessage('users.data.save.success');
      return true;
    } catch (error) {
      console.error(error);
      displayResponseMessage('users.data.save.error');
      return false;
    }
  };
};

export const useResetPasswordAuth = () => {
  const request = useRequest();
  const displayResponseMessage = useDisplayResponseMessage();

  return async () => {
    try {
      await request(API.USERS_CHANGE_PASSWORD(), {
        method: 'POST',
      });
      displayResponseMessage('users.password.reset.success');
      return true;
    } catch (error) {
      console.error(error);
      displayResponseMessage('users.password.reset.error');
      return false;
    }
  };
};

export const useResetPasswordNoAuth = () => {
  const request = useRequest();
  const displayResponseMessage = useDisplayResponseMessage();

  return async (email: string) => {
    try {
      await request(API.USERS_FORGOT_PASSWORD(), {
        method: 'POST',
        data: { email },
      });
      displayResponseMessage('users.password.reset.success');
      return true;
    } catch (error) {
      console.error(error);
      displayResponseMessage('users.password.reset.error');
      return false;
    }
  };
};

export const useSetNewPassword = () => {
  const request = useRequest();
  const { _ } = useLingui();
  const navigate = useNavigate();
  const displayResponseMessage = useDisplayResponseMessage();

  return async ({
    uuid,
    token,
    password1,
    password2,
  }: {
    uuid: string;
    token: string;
    password1: string;
    password2: string;
  }) => {
    if (password1 !== password2) {
      return _(msg`Passwords don't match`);
    }

    try {
      await request(API.USERS_RESET_PASSWORD(uuid, token), {
        method: 'PATCH',
        data: {
          logout: false,
          password1: password1,
          password2: password2,
        },
      });
      navigate(ROUTES.INDEX);
      displayResponseMessage('users.password.new.success');
    } catch (error) {
      console.error(error);
      displayResponseMessage('users.password.new.error');
    }
  };
};
