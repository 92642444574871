import rawMoment from 'moment';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API, QUERY_KEYS, STORAGE_KEYS, COLOR_BY_STATUS_CODE } from '@config';

import {
  useLocalStorage,
  useRequest,
  useActiveFlowUuid,
  useActiveOrganizationUuid,
  useActiveProjectUuid,
  useStatusNameByCode,
  useMoment,
  useDisplayResponseMessage,
} from '@hooks';

import { CodeByStatusName } from '@types';

import { parseAnalyticsData, toStandardDatetime } from '@utils';

export const useAnalyticsOptions = () => {
  const defaultAnalyticsDateRange = useMemo(() => {
    return [rawMoment().startOf('month'), rawMoment().endOf('month')];
  }, []);
  const [analyticsOption, setAnalyticsOption] = useLocalStorage(
    STORAGE_KEYS.ANALYTICS_OPTION,
    'organizations',
  );
  const [analyticsFrequency, setAnalyticsFrequency] = useLocalStorage(
    STORAGE_KEYS.ANALYTICS_FREQUENCY,
    'daily',
  );
  const [analyticsDateRange, setAnalyticsDateRange] = useLocalStorage(
    STORAGE_KEYS.ANALYTICS_DATE_RANGE,
    defaultAnalyticsDateRange,
  );

  return {
    defaultAnalyticsDateRange,
    analyticsOption,
    setAnalyticsOption,
    analyticsFrequency,
    setAnalyticsFrequency,
    analyticsDateRange,
    setAnalyticsDateRange,
  };
};

export const useAnalyticsData = ({
  defaultAnalyticsDateRange,
  analyticsOption,
  analyticsFrequency,
  analyticsDateRange,
}: {
  defaultAnalyticsDateRange: rawMoment.Moment[];
  analyticsOption: 'organizations' | 'projects' | 'flows';
  analyticsFrequency: 'daily' | 'weekly' | 'monthly';
  analyticsDateRange: string[] | rawMoment.Moment[];
}): {
  isSuccess: boolean;
  data: ParsedCallItem[];
  colorByStatusName: {
    [status: string]: string;
  };
} => {
  const moment = useMoment();
  const request = useRequest();
  const displayResponseMessage = useDisplayResponseMessage();

  const organizationUuid = useActiveOrganizationUuid();
  const projectUuid = useActiveProjectUuid();
  const flowUuid = useActiveFlowUuid();

  const queryOption = useMemo(() => {
    let option = analyticsOption;
    if (option === 'flows' && flowUuid === 'all') {
      option = 'projects';
    }
    return option;
  }, [analyticsOption, flowUuid]);

  const queryIdParam = useMemo(() => {
    let param = null;
    if (analyticsOption === 'organizations') {
      param = `organization_uuids=${organizationUuid}`;
    }
    if (analyticsOption === 'projects') {
      param = `project_uuids=${projectUuid}`;
    }
    if (analyticsOption === 'flows') {
      if (flowUuid === 'all') {
        param = `project_uuids=${projectUuid}`;
      } else {
        param = `flow_uuids=${flowUuid}`;
      }
    }
    return param;
  }, [analyticsOption, organizationUuid, projectUuid, flowUuid]);

  const otherQueryParams = useMemo(() => {
    const usableDateRange = analyticsDateRange || defaultAnalyticsDateRange;
    const from = toStandardDatetime(rawMoment(usableDateRange[0]).startOf('day'));
    const to = toStandardDatetime(rawMoment(usableDateRange[1]).endOf('day'));
    return `&date_from=${from}&date_to=${to}`;
  }, [analyticsDateRange, defaultAnalyticsDateRange]);

  const { data, isSuccess } = useQuery({
    enabled:
      !!queryIdParam &&
      queryIdParam?.indexOf('null') === -1 &&
      queryIdParam?.indexOf('undefined') === -1,
    queryKey: [QUERY_KEYS.ANALYTICS, queryOption, queryIdParam, otherQueryParams],
    queryFn: async () => {
      let endpoint;
      switch (queryOption) {
        case 'flows':
          // endpoint = API.ANALYTICS_FLOWS_LOGS(queryIdParam + otherQueryParams);
          endpoint = API.ANALYTICS_FLOWS_NODE_CALLS(queryIdParam + otherQueryParams);
          break;
        case 'organizations':
          // endpoint = API.ANALYTICS_ORGANIZATIONS_LOGS(queryIdParam + otherQueryParams);
          endpoint = API.ANALYTICS_ORGANIZATIONS_NODE_CALLS(
            queryIdParam + otherQueryParams,
          );
          break;
        case 'projects':
          // endpoint = API.ANALYTICS_PROJECTS_LOGS(queryIdParam + otherQueryParams);
          endpoint = API.ANALYTICS_PROJECTS_NODE_CALLS(queryIdParam + otherQueryParams);
          break;
      }

      try {
        const { data } = await request(endpoint);
        return data as {
          total_count: number;
          node_calls: NodeCall[];
        };
      } catch (error) {
        console.error(error);
        displayResponseMessage('analytics.fetch.error');
        return null;
      }
    },
  });

  const parsedData = useMemo(() => {
    // return parseAnalyticsData<FlowCall>(
    //   (data?.logs || []) as FlowCall[],
    //   analyticsFrequency,
    //   moment,
    // );
    return parseAnalyticsData(data?.node_calls || [], analyticsFrequency, moment);
  }, [data, analyticsFrequency, moment]);

  const statusByCode = useStatusNameByCode();

  const colorByStatusName = useMemo(() => {
    const returnObject: {
      [statusName: string]: string;
    } = {};

    for (const statusName in CodeByStatusName) {
      const statusCode = CodeByStatusName[statusName];
      returnObject[statusByCode[statusCode]] = COLOR_BY_STATUS_CODE[statusCode];
    }

    return returnObject;
  }, [statusByCode]);

  const translatedData = useMemo(() => {
    return parsedData.map((item: ParsedCallItem) => {
      const statusName = statusByCode[item.statusCode];
      const color = colorByStatusName[statusName];

      return {
        ...item,
        statusName,
        color,
      };
    });
  }, [parsedData, statusByCode, colorByStatusName]);

  return {
    isSuccess,
    data: translatedData as ParsedCallItem[],
    colorByStatusName,
  };
};
