import { scssVariables } from '@styles';

import { CodeByStatusName } from '@types';

type Identifiable = {
  _id?: string;
  uuid?: string;
};

export const getTableData = <T extends Identifiable>(data: T[]) => {
  return data.map((obj: T) => ({
    ...obj,
    key: obj._id || obj.uuid,
  }));
};

export const getRowClassNameByStatus = (status: CallStatus) => {
  switch (status.code) {
    case CodeByStatusName.success: {
      return 'table-row-success';
    }
    case CodeByStatusName.failed: {
      return 'table-row-failed';
    }
    case CodeByStatusName.retrying: {
      return 'table-row-retrying';
    }
    case CodeByStatusName.in_progress: {
      return 'table-row-in-progress';
    }
    case CodeByStatusName.in_queue: {
      return 'table-row-in-queue';
    }
    default: {
      return '';
    }
  }
};

export const FLOW_CALLS_TABLE_SCROLL_SETTINGS_1 = {
  // Subtracting
  // - header height
  // - schema height
  // - container padding
  // - table header height
  // - pagination height
  // (also container has overflow-y hidden)
  y: `calc(
    100vh
    - ${scssVariables.headerHeight}
    - ${scssVariables.dashboardSchemaHeight}
    - ${scssVariables.dashboardConfigurationHeight}
    - 1rem
    - 4.7rem
    - 6.4rem
  )`,
  scrollToFirstRowOnChange: true,
};

// Same as the previous one but with schema height removed
export const FLOW_CALLS_TABLE_SCROLL_SETTINGS_2 = {
  y: `calc(
    100vh
    - ${scssVariables.headerHeight}
    - ${scssVariables.dashboardConfigurationHeight}
    - 1rem
    - 4.7rem
    - 6.4rem
  )`,
  scrollToFirstRowOnChange: true,
};

export const NODE_CALLS_TABLE_SCROLL_SETTINGS = {
  // Subtracting
  // - header height
  // - schema height
  // - container padding
  // - table header height
  // (also container has overflow-y hidden)
  y: `calc(
    100vh
    - ${scssVariables.headerHeight}
    - ${scssVariables.flowCallSchemaHeight}
    - 2.6rem
    - 4.7rem
  )`,
  scrollToFirstRowOnChange: true,
};
