import { useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { API, QUERY_KEYS } from '@config';

import { useUserState, useRequest, useDisplayResponseMessage } from '@hooks';

import {
  OptionsContext,
  setAppInfo,
  setOrganizations,
  setProjects,
  setFlows,
  setActiveOrganization,
  setActiveProject,
  setActiveFlow,
} from '@reducers';

export const useOptionsContext = () => {
  const context = useContext(OptionsContext);
  return context;
};

export const useOptions = () => {
  const { state } = useContext(OptionsContext);
  return state;
};

export const useOptionsDispatch = () => {
  const { dispatch } = useContext(OptionsContext);
  return dispatch;
};

export const useFetchAppInfo = (): void => {
  const request = useRequest();
  const dispatch = useOptionsDispatch();
  const displayResponseMessage = useDisplayResponseMessage();

  useQuery({
    queryKey: [QUERY_KEYS.APP_INFO],
    queryFn: async () => {
      try {
        const { data } = await request(API.CORE_APP_INFO());
        dispatch(setAppInfo(data as AppInfo));
        return data as AppInfo;
      } catch (error) {
        console.error(error);
        displayResponseMessage('app_info.fetch.error');
        return null;
      }
    },
  });
};

export const useAppInfo = () => {
  const { state } = useContext(OptionsContext);
  return state.appInfo;
};

export const useFetchOptions = (): void => {
  const request = useRequest();
  const dispatch = useOptionsDispatch();
  const { loggedIn } = useUserState();
  const displayResponseMessage = useDisplayResponseMessage();

  // Fetch available organizations
  useQuery({
    enabled: loggedIn,
    queryKey: [QUERY_KEYS.ORGANIZATIONS],
    queryFn: async () => {
      try {
        const { data } = await request(API.CORE_ORGANIZATIONS_LIST());
        dispatch(setOrganizations(data as Organization[]));
        return data as Organization[];
      } catch (error) {
        console.error(error);
        displayResponseMessage('organizations.fetch.error');
        return null;
      }
    },
  });

  const activeOrganization = useActiveOrganization();

  // Fetch available projects
  useQuery({
    enabled: !!activeOrganization?.uuid,
    queryKey: [QUERY_KEYS.PROJECTS, activeOrganization?.uuid],
    queryFn: async () => {
      try {
        const { data } = await request(
          API.CORE_PROJECTS_LIST(activeOrganization?.uuid as string),
        );
        dispatch(setProjects(data as Project[]));
        return data as Project[];
      } catch (error) {
        console.error(error);
        displayResponseMessage('projects.fetch.error');
        return null;
      }
    },
  });

  const activeProject = useActiveProject();

  // Fetch available flows
  useQuery({
    enabled: !!activeProject?.uuid,
    queryKey: [QUERY_KEYS.FLOWS, activeProject?.uuid],
    queryFn: async () => {
      try {
        const { data } = await request(API.FLOWS_LIST(activeProject?.uuid as string));
        dispatch(setFlows(data as Flow[]));
        return data as Flow[];
      } catch (error) {
        console.error(error);
        displayResponseMessage('flows.fetch.error');
        return null;
      }
    },
  });
};

export const useRefetchOptions = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANIZATIONS] });
  };
};

export const useOrganizations = () => {
  const { state } = useContext(OptionsContext);
  return state.organizations;
};

export const useActiveOrganization = () => {
  const { state } = useContext(OptionsContext);
  return state.activeOrganization;
};

export const useActiveOrganizationUuid = () => {
  const { state } = useContext(OptionsContext);
  return state.activeOrganization?.uuid;
};

export const useSetActiveOrganization = () => {
  const dispatch = useOptionsDispatch();
  return (organizationUuid: string) => {
    dispatch(setActiveOrganization(organizationUuid));
  };
};

export const useProjects = () => {
  const { state } = useContext(OptionsContext);
  return state.projects;
};

export const useActiveProject = () => {
  const { state } = useContext(OptionsContext);
  return state.activeProject;
};

export const useActiveProjectUuid = () => {
  const { state } = useContext(OptionsContext);
  return state.activeProject?.uuid;
};

export const useSetActiveProject = () => {
  const dispatch = useOptionsDispatch();
  return (projectUuid: string) => {
    dispatch(setActiveProject(projectUuid));
  };
};

export const useFlows = () => {
  const { state } = useContext(OptionsContext);
  return state.flows;
};

export const useActiveFlow = () => {
  const { state } = useContext(OptionsContext);
  return state.activeFlow;
};

export const useActiveFlowUuid = () => {
  const { state } = useContext(OptionsContext);
  return state.activeFlow === 'all' ? state.activeFlow : state.activeFlow?.uuid;
};

export const useSetActiveFlow = () => {
  const dispatch = useOptionsDispatch();
  return (flowUuid: string) => {
    dispatch(setActiveFlow(flowUuid));
  };
};

export const useLeaveOrganization = () => {
  const request = useRequest();
  const refetchOptions = useRefetchOptions();
  const displayResponseMessage = useDisplayResponseMessage();

  return async (organization: Organization) => {
    try {
      await request(API.CORE_ORGANIZATIONS_LEAVE(organization.uuid), {
        method: 'POST',
        data: { organization },
      });
      refetchOptions();
      displayResponseMessage('organizations.leave.success', organization);
    } catch (error) {
      console.error(error);
      displayResponseMessage('organizations.leave.error', organization);
    }
  };
};
