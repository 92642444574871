import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';

import { ROUTES } from '@config';

import { useUserState } from '@hooks';

import styles from './NotFoundPage.module.scss';

const NotFoundPage = () => {
  const { loggedIn } = useUserState();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Result
          status="404"
          title={<p className={styles.code}>404</p>}
          subTitle={
            <p className={styles.text}>
              <Trans>This page does not exist</Trans>
            </p>
          }
          extra={
            <Link to={loggedIn ? ROUTES.DASHBOARD : ROUTES.LOGIN}>
              <Button
                className="button-theme2"
                type="primary"
                shape="round"
                size="large"
                icon={<LogoutOutlined />}
                data-testid="404-button"
              >
                {loggedIn ? (
                  <span>
                    <Trans>Go to Dashboard</Trans>
                  </span>
                ) : (
                  <span>
                    <Trans>Log in</Trans>
                  </span>
                )}
              </Button>
            </Link>
          }
        />
      </div>
    </div>
  );
};

export default NotFoundPage;
