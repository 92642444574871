import { useFetchAppInfo, useFetchUserData, useFetchOptions } from '@hooks';

const WithInitialData = ({ children }: React.PropsWithChildren) => {
  useFetchAppInfo();
  useFetchUserData();
  useFetchOptions();

  return children;
};

export default WithInitialData;
