import { Select } from 'antd';

import { useLanguage, useSetLanguage } from '@hooks';

import { getSelectOptions } from '@utils';

const LanguagePicker = () => {
  const language = useLanguage();
  const setLanguage = useSetLanguage();

  return (
    <Select
      options={getSelectOptions(
        [
          { id: 'en', name: 'en' },
          { id: 'cs', name: 'cs' },
        ],
        'name',
        'id',
      )}
      value={language}
      onChange={(language: LanguageCode) => {
        setLanguage(language);
      }}
      data-testid="language-select"
      dropdownRender={(OriginDropdown) => (
        <div data-testid="language-select-dropdown">{OriginDropdown}</div>
      )}
    />
  );
};

export default LanguagePicker;
