import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useUser, useUploadAvatar, useRemoveAvatar } from '@hooks';

const AvatarUpload = () => {
  const { _ } = useLingui();
  const user = useUser();
  const uploadAvatar = useUploadAvatar();
  const removeAvatar = useRemoveAvatar();

  const fileList = user.avatar
    ? [
        {
          uid: user.avatar,
          name: 'Profile Image',
          url: user.avatar,
        },
      ]
    : [];

  const renderUpload = () => (
    <Upload
      listType="picture-card"
      maxCount={1}
      onRemove={() => removeAvatar()}
      customRequest={({ file }) => uploadAvatar(file as File)}
      fileList={fileList}
      data-testid="avatar-upload"
    >
      {!user.avatar && _(msg`+ Upload`)}
    </Upload>
  );

  // I didn't manage to mock this, therefore
  // we at least test the upload itself
  if (process.env.NODE_ENV === 'test') {
    return renderUpload();
  } else {
    return <ImgCrop modalTitle={_(msg`Edit image`)}>{renderUpload()}</ImgCrop>;
  }
};

export default AvatarUpload;
