import { ReactComponent as Logo } from '@assets/logo_cerne.svg';

import { HeaderSelects, LanguagePicker, UserDropdown } from '@components';

import styles from './Header.module.scss';

const Header = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <Logo className={styles.logo} />
        <HeaderSelects />
      </div>
      <div className={styles.rightSide}>
        <LanguagePicker />
        <UserDropdown />
      </div>
    </div>
  );
};

export default Header;
