import React, { Suspense } from 'react';
import { renderToString } from 'react-dom/server';

// https://github.com/ant-design/ant-design/issues/40644#issuecomment-1722804109
const Column = React.lazy(async () => {
  const module = await import('@ant-design/plots');
  return { default: module.Column };
});

type TooltipItem = {
  color: string;
  name: string;
  origin: ParsedCallItem;
};

type LegendItem = {
  id: string;
  label: string;
};

const displayTooltipItems = (items: TooltipItem[]) => {
  return items.map((item: TooltipItem) => {
    const { name, origin } = item;
    return (
      <div key={name}>
        <div
          style={{
            margin: 0,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <span
              style={{
                display: 'inline-block',
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: origin.color,
                marginRight: 6,
              }}
            ></span>
            <span>{name}</span>
          </div>
          <b>{origin.value}</b>
        </div>
      </div>
    );
  });
};

type CallItemsChartProps = {
  data: ParsedCallItem[];
  colorByStatusName: { [status: string]: string };
};

const CallItemsChart = ({ data, colorByStatusName }: CallItemsChartProps) => {
  // For some reason, this doesn't work in tests
  if (process.env.NODE_ENV === 'test') {
    return null;
  }

  return (
    <Suspense>
      <Column
        data={data}
        xField="start"
        yField="value"
        colorField="statusName"
        stack={true}
        height={500}
        // style for each bar
        style={{
          radiusTopLeft: 4,
          radiusTopRight: 4,
          fill: ({ color }: ParsedCallItem) => color,
          maxWidth: 200,
        }}
        legend={{
          color: {
            itemMarkerFill: ({ id }: LegendItem) => {
              return colorByStatusName[id];
            },
          },
        }}
        tooltip={(item) => ({
          origin: item,
        })}
        interaction={{
          tooltip: {
            render: (
              _: PointerEvent,
              { title, items }: { title: string; items: TooltipItem[] },
            ) => {
              const content = (
                <div>
                  <h4>{title}</h4>
                  {displayTooltipItems(items)}
                </div>
              );

              // For some reason, in development, this method doesn't work
              // with actual elements, it needs to return a string,
              // this is one of the weirdest things I've ever seen
              if (process.env.NODE_ENV === 'development') {
                return renderToString(content);
              } else {
                return content;
              }
            },
          },
        }}
      />
    </Suspense>
  );
};

export default CallItemsChart;
