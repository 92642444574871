import { Tag } from 'antd';

import { COLOR_BY_STATUS_CODE } from '@config';

import { useStatusNameByCode } from '@hooks';

type StatusTagProps = {
  status: CallStatus;
};

const StatusTag = ({ status }: StatusTagProps) => {
  const statusNameByCode = useStatusNameByCode();

  return (
    <Tag color={COLOR_BY_STATUS_CODE[status.code]}>{statusNameByCode[status.code]}</Tag>
  );
};

export default StatusTag;
