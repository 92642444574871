// https://ant.design/docs/react/use-custom-date-library
import { forwardRef } from 'react';
import { DatePicker as AntdDatePicker, Calendar as AntdCalendar } from 'antd';
import type { PickerProps } from 'antd/es/date-picker/generatePicker';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

// eslint-disable-next-line
export const DatePicker: any =
  AntdDatePicker.generatePicker<Moment>(momentGenerateConfig);

export interface TimePickerProps extends Omit<PickerProps<Moment>, 'picker'> {}

// eslint-disable-next-line
export const TimePicker = forwardRef<any, TimePickerProps>((props, ref) => (
  <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
));
TimePicker.displayName = 'TimePicker';

export const Calendar = AntdCalendar.generateCalendar<Moment>(momentGenerateConfig);
