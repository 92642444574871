import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { PlayCircleFilled } from '@ant-design/icons';
import { Trans } from '@lingui/macro';

import { useActiveFlow, useExecuteFlow } from '@hooks';

import styles from './FlowExecutionButton.module.scss';

const FlowExecutionButton: React.FC = () => {
  const flow = useActiveFlow() as Flow;
  const executeFlow = useExecuteFlow(flow);
  const [isExecuting, setIsExecuting] = useState(false);

  const onExecuteFlow = async () => {
    if (isExecuting) {
      return;
    }

    setIsExecuting(true);
    executeFlow(() => {
      setIsExecuting(false);
    });
  };

  return (
    <div className={styles.container}>
      <Tooltip title={<Trans>Run once</Trans>}>
        <Button
          className={styles.playButton}
          type="text"
          icon={<PlayCircleFilled className={styles.playIcon} />}
          onClick={onExecuteFlow}
          loading={isExecuting}
        />
      </Tooltip>
    </div>
  );
};

export default FlowExecutionButton;
