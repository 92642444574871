import React from 'react';
import { createRoot } from 'react-dom/client';

import {
  AppRouter,
  WithAntdConfig,
  WithProviders,
  WithInitialData,
  WithLingui,
  WithQueryClient,
} from '@app';

import '@styles/styles.scss';
import 'antd/dist/reset.css';
import 'moment/dist/locale/cs';
import 'reactflow/dist/style.css';

import './sentry';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <WithQueryClient>
      <WithProviders>
        <WithLingui>
          <WithAntdConfig>
            <WithInitialData>
              <AppRouter />
            </WithInitialData>
          </WithAntdConfig>
        </WithLingui>
      </WithProviders>
    </WithQueryClient>
  </React.StrictMode>,
);
