import { createContext } from 'react';

import { STORAGE_KEYS } from '@config';

const defaultContextValue = {} as OptionsContextType;

type OptionsState = {
  appInfo: AppInfo;
  organizations: Organization[];
  projects: Project[];
  flows: Flow[];
  activeOrganization: Organization | null;
  activeProject: Project | null;
  activeFlow: Flow | 'all' | null;
};

type OptionsAction = {
  type: string;
  appInfo?: AppInfo;
  organizations?: Organization[];
  projects?: Project[];
  flows?: Flow[];
  organizationUuid?: string | null;
  projectUuid?: string | null;
  flowUuid?: string | null;
};

type OptionsContextType = {
  state: OptionsState;
  dispatch: React.Dispatch<OptionsAction>;
};

export const OptionsContext = createContext<OptionsContextType>(defaultContextValue);

export const initialOptionsState: OptionsState = {
  appInfo: {},
  organizations: [],
  projects: [],
  flows: [],
  activeOrganization: null,
  activeProject: null,
  activeFlow: null,
};

// Loads organization from local storage if valid
const loadActiveOrganization = (organizations: Organization[]): Organization | null => {
  const defaultOrganization = organizations[0] || null;

  const lastOrganizationUuid = localStorage.getItem(STORAGE_KEYS.ORGANIZATION);
  const lastOrganization = organizations.filter((organization: Organization) => {
    return organization.uuid === lastOrganizationUuid;
  })[0];

  return lastOrganization || defaultOrganization;
};

// Loads project from local storage if valid
const loadActiveProject = (projects: Project[]): Project | null => {
  const defaultProject = projects[0] || null;

  const lastProjectUuid = localStorage.getItem(STORAGE_KEYS.PROJECT);
  const lastProject = projects.filter((project: Project) => {
    return project.uuid === lastProjectUuid;
  })[0];

  return lastProject || defaultProject;
};

// Loads flow from local storage if valid
const loadActiveFlow = (flows: Flow[]): Flow | 'all' | null => {
  const defaultFlow = 'all';

  const lastFlowUuid = localStorage.getItem(STORAGE_KEYS.FLOW);
  const lastFlow = flows.filter((flow: Flow) => {
    return flow.uuid === lastFlowUuid;
  })[0];

  return lastFlow || defaultFlow;
};

export const optionsReducer = (
  state: OptionsState,
  action: OptionsAction,
): OptionsState => {
  switch (action.type) {
    case 'SET_APP_INFO': {
      const appInfo = action?.appInfo as AppInfo;

      return {
        ...state,
        appInfo,
      };
    }
    case 'SET_ORGANIZATIONS': {
      const organizations = action?.organizations || [];
      const activeOrganization = loadActiveOrganization(organizations);
      localStorage.setItem(STORAGE_KEYS.ORGANIZATION, activeOrganization?.uuid || '');

      return {
        ...state,
        organizations: organizations,
        activeOrganization: activeOrganization,
      };
    }
    case 'SET_PROJECTS': {
      const projects = action?.projects || [];
      const activeProject = loadActiveProject(projects);
      localStorage.setItem(STORAGE_KEYS.PROJECT, activeProject?.uuid || '');

      return {
        ...state,
        projects: projects,
        activeProject: activeProject,
      };
    }
    case 'SET_FLOWS': {
      const flows = action?.flows || [];
      const activeFlow = loadActiveFlow(flows);
      localStorage.setItem(
        STORAGE_KEYS.FLOW,
        activeFlow === 'all' ? activeFlow : activeFlow?.uuid || '',
      );

      return {
        ...state,
        flows: flows,
        activeFlow: activeFlow,
      };
    }
    case 'SET_ACTIVE_ORGANIZATION': {
      const organizationUuid = action.organizationUuid;

      localStorage.setItem(STORAGE_KEYS.ORGANIZATION, organizationUuid || '');

      const organization = state.organizations.filter((organization: Organization) => {
        return organization.uuid === organizationUuid;
      })[0];

      return {
        ...state,
        activeOrganization: organization || null,
      };
    }
    case 'SET_ACTIVE_PROJECT': {
      const projectUuid = action.projectUuid;

      localStorage.setItem(STORAGE_KEYS.PROJECT, projectUuid || '');

      const project = state.projects.filter((project: Project) => {
        return project.uuid === projectUuid;
      })[0];

      return {
        ...state,
        activeProject: project || null,
      };
    }
    case 'SET_ACTIVE_FLOW': {
      const flowUuid = action.flowUuid;

      localStorage.setItem(STORAGE_KEYS.FLOW, flowUuid || '');

      let flow: Flow | 'all' = 'all';
      if (flowUuid !== 'all') {
        flow = state.flows.filter((flow: Flow) => {
          return flow.uuid === flowUuid;
        })[0];
      }

      return {
        ...state,
        activeFlow: flow || null,
      };
    }
    default:
      return state;
  }
};

export const setAppInfo = (appInfo: AppInfo) => ({
  type: 'SET_APP_INFO',
  appInfo,
});

export const setOrganizations = (organizations: Organization[]) => ({
  type: 'SET_ORGANIZATIONS',
  organizations,
});

export const setProjects = (projects: Project[]) => ({
  type: 'SET_PROJECTS',
  projects,
});

export const setFlows = (flows: Flow[]) => ({
  type: 'SET_FLOWS',
  flows,
});

export const setActiveOrganization = (organizationUuid: string | null) => ({
  type: 'SET_ACTIVE_ORGANIZATION',
  organizationUuid: organizationUuid,
});

export const setActiveProject = (projectUuid: string | null) => ({
  type: 'SET_ACTIVE_PROJECT',
  projectUuid: projectUuid,
});

export const setActiveFlow = (flowUuid: string | null) => ({
  type: 'SET_ACTIVE_FLOW',
  flowUuid: flowUuid,
});
