import { CodeByStatusName } from '@types';

export const THEME = {
  token: {
    // Should be the same as in styles/variables.scss
    colorPrimary: '#3452f8',
  },
  components: {
    Form: {
      itemMarginBottom: 0,
    },
  },
};

// Colors should match status colors styles/variables.scss
export const COLOR_BY_STATUS_CODE: {
  [statusCode: string]: string;
} = {
  [CodeByStatusName.success]: '#45d470',
  [CodeByStatusName.failed]: '#fe4955',
  [CodeByStatusName.retrying]: '#49fef2',
  [CodeByStatusName.in_progress]: '#f8d135',
  [CodeByStatusName.in_queue]: '#bbb',
};
