// import { AxiosError } from 'axios';
import {
  useQuery,
  useQueryClient,
  // useQueryClient,
} from '@tanstack/react-query';

import { API, QUERY_KEYS } from '@config';

import { useRequest, useDisplayResponseMessage } from '@hooks';

export const useConnections = (
  organization: Organization | null,
): {
  isSuccess: boolean;
  data: Connection[];
} => {
  const request = useRequest();
  const displayResponseMessage = useDisplayResponseMessage();

  const { isSuccess, data } = useQuery({
    enabled: !!organization,
    queryKey: [
      QUERY_KEYS.CONNECTIONS,
      QUERY_KEYS.ORGANIZATION_CONNECTIONS,
      organization?.uuid,
    ],
    queryFn: async () => {
      try {
        const { data } = await request(
          API.CORE_ORGANIZATIONS_CONNECTIONS(organization?.uuid as string),
        );
        return data.connections as Connection[];
      } catch (error) {
        console.error(error);
        displayResponseMessage('connections.fetch.error');
        return null;
      }
    },
  });

  return {
    isSuccess,
    data: data || [],
  };
};

// export const useCreateConnection = () => {
//   const request = useRequest();
//   const queryClient = useQueryClient();
//   const displayResponseMessage = useDisplayResponseMessage();

//   return async (data: {
//     nodetype: string;
//     organization: string;
//     name: string;
//     data: { [key: string]: string };
//   }) => {
//     try {
//       await request(API.CONNECTIONS_CREATE(), {
//         method: 'POST',
//         data,
//       });
//       queryClient.invalidateQueries({
//         queryKey: [
//           QUERY_KEYS.CONNECTIONS,
//           QUERY_KEYS.NODE_CONNECTION_OPTIONS,
//           data.nodetype,
//           data.organization,
//         ],
//       });
//       displayResponseMessage('connections.create.success');
//       return true;
//     } catch (error) {
//       console.error(error);
//       displayResponseMessage('connections.create.error');
//       return false;
//     }
//   };
// };

export const useDeleteConnection = (connection: Connection) => {
  const request = useRequest();
  const queryClient = useQueryClient();
  const displayResponseMessage = useDisplayResponseMessage();

  return async () => {
    try {
      await request(API.CONNECTIONS_DELETE(connection.uuid), {
        method: 'DELETE',
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CONNECTIONS],
      });
      displayResponseMessage('connections.delete.success', connection);
    } catch (error) {
      console.error(error);
      displayResponseMessage('connections.delete.error', connection);
    }
  };
};

// export const useVerifyConnection = (connection: Connection) => {
//   const request = useRequest();
//   const displayResponseMessage = useDisplayResponseMessage();

//   return async () => {
//     try {
//       await request(API.CONNECTIONS_VERIFY(connection.id), {
//         method: 'POST',
//       });
//       displayResponseMessage('connections.verify.success', connection);
//     } catch (error) {
//       console.error(error);
//       if (error instanceof AxiosError) {
//         displayResponseMessage(
//           'connections.verify.error',
//           connection,
//           error.response?.data?.error_text,
//         );
//       }
//     }
//   };
// };

// export const useDisconnectConnection = (connection: Connection) => {
//   const request = useRequest();
//   const queryClient = useQueryClient();
//   const displayResponseMessage = useDisplayResponseMessage();

//   return async () => {
//     try {
//       await request(API.CONNECTIONS_DISCONNECT(connection.id), {
//         method: 'POST',
//       });
//       queryClient.invalidateQueries({
//         queryKey: [QUERY_KEYS.ORGANIZATION_CONNECTIONS],
//       });
//       displayResponseMessage('connections.disconnect.success', connection);
//     } catch (error) {
//       console.error(error);
//       displayResponseMessage('connections.disconnect.error', connection);
//     }
//   };
// };
