import {
  DashboardConfiguration,
  DashboardFlowSchema,
  FlowCallsTable,
} from '@components';

import { STORAGE_KEYS } from '@config';

import { useActiveFlow, useLocalStorage, useSchemaHider } from '@hooks';

const DashboardPage = () => {
  const activeFlow = useActiveFlow();
  const { showSchema, SchemaHider } = useSchemaHider();
  const allowSchemaManipulation = activeFlow && activeFlow !== 'all';
  const schemaVisible = showSchema && allowSchemaManipulation;
  const [dashboardConfiguration, setDashboardConfiguration] = useLocalStorage(
    STORAGE_KEYS.DASHBOARD_CONFIGURATION,
    { refreshValue: 30 * 1000 },
  );

  return (
    <div>
      <div />
      {allowSchemaManipulation && <SchemaHider />}
      {schemaVisible && <DashboardFlowSchema />}
      <DashboardConfiguration
        dashboardConfiguration={dashboardConfiguration}
        setDashboardConfiguration={setDashboardConfiguration}
      />
      <FlowCallsTable
        schemaVisible={schemaVisible}
        dashboardConfiguration={dashboardConfiguration}
      />
    </div>
  );
};

export default DashboardPage;
