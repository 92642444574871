import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Button } from 'antd';
import {
  RightOutlined,
  LeftOutlined,
  HomeOutlined,
  ApartmentOutlined,
  LinkOutlined,
  // BranchesOutlined,
  BarChartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Trans } from '@lingui/macro';

import { Header } from '@components';

import { ROUTES, STORAGE_KEYS } from '@config';

import { useAppInfo, useLocalStorage } from '@hooks';

import styles from './PageLayout.module.scss';

const { Sider } = Layout;

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => {
  const [collapsed, setCollapsed] = useLocalStorage(STORAGE_KEYS.SIDER, true);
  const appInfo = useAppInfo();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout hasSider>
        <Sider
          className={styles.sider}
          collapsible
          collapsedWidth={50}
          collapsed={collapsed}
          trigger={null}
          theme="light"
        >
          <div className={styles.collapseButtonContainer}>
            <Button
              size="small"
              shape="circle"
              icon={
                collapsed ? (
                  <RightOutlined className={styles.uncollapseIcon} />
                ) : (
                  <LeftOutlined className={styles.collapseIcon} />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
              data-testid={collapsed ? 'collapse-button-open' : 'collapse-button-close'}
            />
          </div>
          <Menu
            selectedKeys={[location.pathname]}
            items={[
              {
                key: ROUTES.DASHBOARD,
                icon: <HomeOutlined />,
                label: (
                  <span className={styles.menuLabel}>
                    <Trans>Dashboard</Trans>
                  </span>
                ),
                onClick: () => navigate(ROUTES.DASHBOARD),
              },
              {
                key: ROUTES.FLOW_BUILDER,
                icon: <ApartmentOutlined />,
                label: (
                  <span className={styles.menuLabel}>
                    <Trans>Flow Builder</Trans>
                  </span>
                ),
                onClick: () => navigate(ROUTES.FLOW_BUILDER),
              },
              {
                key: ROUTES.CONNECTIONS,
                icon: <LinkOutlined />,
                label: (
                  <span className={styles.menuLabel}>
                    <Trans>Connections</Trans>
                  </span>
                ),
                onClick: () => navigate(ROUTES.CONNECTIONS),
              },
              // {
              //   key: ROUTES.WEBHOOKS,
              //   icon: <BranchesOutlined />,
              //   label: (
              //     <span className={styles.menuLabel}>
              //       <Trans>Webhooks</Trans>
              //     </span>
              //   ),
              //   onClick: () => navigate(ROUTES.WEBHOOKS),
              // },
              {
                key: ROUTES.ANALYTICS,
                icon: <BarChartOutlined />,
                label: (
                  <span className={styles.menuLabel}>
                    <Trans>Statistics</Trans>
                  </span>
                ),
                onClick: () => navigate(ROUTES.ANALYTICS),
              },
              {
                key: ROUTES.PROFILE,
                icon: <UserOutlined />,
                label: (
                  <span className={styles.menuLabel}>
                    <Trans>Profile</Trans>
                  </span>
                ),
                onClick: () => navigate(ROUTES.PROFILE),
              },
            ]}
          />
          <div className={styles.appVersionContainer}>v{appInfo.version}</div>
        </Sider>
        <Layout>
          <div className={styles.content}>{children}</div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PageLayout;
