import { Button } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

type ShowHideButtonProps = {
  visible: boolean;
  onChange: (state: boolean) => void;
  showText?: string;
  hideText?: string;
};

const ShowHideButton = ({
  visible,
  onChange,
  showText,
  hideText,
}: ShowHideButtonProps) => {
  const { _ } = useLingui();
  if (!showText) showText = _(msg`Show`);
  if (!hideText) hideText = _(msg`Hide`);

  return visible ? (
    <Button
      type="primary"
      shape="round"
      icon={<EyeInvisibleOutlined />}
      onClick={() => onChange(!visible)}
      data-testid="show-hide-button"
    >
      {hideText}
    </Button>
  ) : (
    <Button
      type="primary"
      shape="round"
      icon={<EyeOutlined />}
      onClick={() => onChange(!visible)}
      data-testid="show-hide-button"
    >
      {showText}
    </Button>
  );
};

export default ShowHideButton;
