import { CodeByStatusName, LocalizedMoment } from '@types';

import { dayMonthYear, monthYear, sortByDateStringProperty } from '@utils';

export const parseAnalyticsData = <T extends CallItem>(
  items: T[],
  frequency: 'daily' | 'weekly' | 'monthly',
  moment: LocalizedMoment,
): ParsedCallItem[] => {
  // Items are grouped by status and date based on frequency,
  // if the frequency is 'monthly', we only use the month and so on
  const createGroupKey = (item: T) => {
    let groupKey = `${item.status.code.toString()}_`;
    if (frequency === 'daily') {
      groupKey += moment(item.start).format('YYYY-MM-DD');
    } else if (frequency === 'weekly') {
      groupKey += moment(item.start).format('YYYY-W');
    } else if (frequency === 'monthly') {
      groupKey += moment(item.start).format('YYYY-MM');
    }
    return groupKey;
  };

  items = items.map((item: T) => {
    return {
      ...item,
      groupKey: createGroupKey(item),
    };
  });

  const formatItemDate = (date: string) => {
    if (frequency === 'daily') {
      return dayMonthYear(moment(date));
    } else if (frequency === 'weekly') {
      return dayMonthYear(moment(date).startOf('week'));
    } else if (frequency === 'monthly') {
      return monthYear(moment(date));
    } else {
      return monthYear(moment(date));
    }
  };

  // // Uncomment and adjust for testing purposes
  // items = [
  //   ...items,
  //   ...Array.from(Array(15).keys()).map((idx: number) => ({
  //     start: '2024-03-' + (idx + 10),
  //     status: { code: 0, name: 'Success' },
  //     groupKey: '0_' + '2024-03-' + (idx + 10),
  //   })),
  // ];

  const groupedItems = items.reduce(
    (accumulator: { [key: string]: ParsedCallItem }, item: T) => {
      const groupKey = item.groupKey as string;
      if (!accumulator[groupKey]) {
        accumulator[groupKey] = {
          _start: item.start,
          start: formatItemDate(item.start),
          statusCode: item.status.code,
          statusName: item.status.name,
          value: 1,
        };
      } else {
        accumulator[groupKey].value++;
      }
      return accumulator;
    },
    {},
  );

  const parsedItems = Object.values(groupedItems) as ParsedCallItem[];

  // First, sort by date
  const sortedByDate = sortByDateStringProperty(parsedItems, '_start');

  // Second, sort by status (within groups)
  const statusOrder: { [key: number]: number } = {
    [CodeByStatusName.success]: 4,
    [CodeByStatusName.failed]: 1,
    [CodeByStatusName.retrying]: 2,
    [CodeByStatusName.in_progress]: 3,
    [CodeByStatusName.in_queue]: 0,
  };
  sortedByDate.sort((a: ParsedCallItem, b: ParsedCallItem) => {
    if (a.start === b.start) {
      return statusOrder[b.statusCode] - statusOrder[a.statusCode];
    } else {
      return 0;
    }
  });

  return sortedByDate;
};
