import { Button, Card, Popconfirm } from 'antd';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useOrganizations, useLeaveOrganization } from '@hooks';

import styles from './OrganizationList.module.scss';

const OrganizationList = () => {
  const { _ } = useLingui();
  const organizations = useOrganizations();
  const leaveOrganization = useLeaveOrganization();

  return (
    <Card
      title={
        <span>
          <Trans>Organizations</Trans>
        </span>
      }
    >
      <div className={styles.content}>
        {organizations.map((organization: Organization) => (
          <div
            className={styles.organizationItem}
            key={organization.uuid}
            data-testid="organization-item"
          >
            <span className={styles.organizationName}>{organization.name}</span>
            <Popconfirm
              title={_(msg`Are you sure you want to leave ${organization.name}?`)}
              okText={<div data-testid="yes-button">Yes</div>}
              cancelText="No"
              onConfirm={() => leaveOrganization(organization)}
            >
              <Button danger data-testid="leave-organization-button">
                <Trans>Leave</Trans>
              </Button>
            </Popconfirm>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default OrganizationList;
