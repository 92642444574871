import { Card, Button, Tooltip, Popconfirm } from 'antd';
import {
  AppstoreOutlined,
  DeleteOutlined,
  // EyeOutlined,
  // CheckCircleOutlined,
  // DisconnectOutlined,
} from '@ant-design/icons';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  useMoment,
  useDeleteConnection,
  // useVerifyConnection,
  // useDisconnectConnection,
} from '@hooks';

import { dayMonthYear } from '@utils';

import styles from './ConnectionItem.module.scss';

const ConnectionItem = ({ connection }: { connection: Connection }) => {
  const { _ } = useLingui();
  const moment = useMoment();
  const deleteConnection = useDeleteConnection(connection);
  // const verifyConnection = useVerifyConnection(connection);
  // const disconnectConnection = useDisconnectConnection(connection);

  return (
    <Card className={styles.card} bordered={false} data-testid="connection-item">
      <div className={styles.cardContent}>
        <div className={styles.rightSide}>
          <h3>{connection.name}</h3>
          {/* <span>{connection.description}</span> */}
          <span>
            <Trans>Created on</Trans> {dayMonthYear(moment(connection.created_at))}
          </span>
        </div>
        <div className={styles.leftSide}>
          <Tooltip title={_(msg`Number of nodes using this connection`)}>
            <AppstoreOutlined className={styles.numberIcon} />
            <span className={styles.number}>{connection.node_count}</span>
          </Tooltip>
          <Popconfirm
            title={_(msg`Are you sure you want to delete this connection?`)}
            onConfirm={() => deleteConnection()}
            okText={_(msg`Yes`)}
            cancelText={_(msg`No`)}
            placement="topLeft"
          >
            <Button icon={<DeleteOutlined />} danger>
              <span>
                <Trans>Delete</Trans>
              </span>
            </Button>
          </Popconfirm>
          {/* <Tooltip title={_(msg`Number of permissions`)}>
            <EyeOutlined className={styles.numberIcon} />
            <span className={styles.number}>{connection.number_of_permissions}</span>
          </Tooltip> */}
          {/* <Button
            type="primary"
            icon={<CheckCircleOutlined />}
            onClick={() => verifyConnection()}
          >
            <span>
              <Trans>Verify</Trans>
            </span>
          </Button> */}
          {/* <Button
            type="primary"
            icon={<DisconnectOutlined />}
            onClick={() => disconnectConnection()}
            danger
          >
            <span>
              <Trans>Disconnect</Trans>
            </span>
          </Button> */}
        </div>
      </div>
    </Card>
  );
};

export default ConnectionItem;
