import { COOKIES } from '@config';

import { readCookie } from './cookie';

export const getRequestHeaders = () => {
  const headers: { [key: string]: string } = {
    Accept: 'application/json; charset=UTF-8',
  };

  const authToken = readCookie(COOKIES.AUTH_TOKEN, '');
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  const clientCSRFtoken = readCookie(COOKIES.CSRF_TOKEN, '');
  if (clientCSRFtoken) {
    headers['X-CSRFToken'] = clientCSRFtoken;
  }

  return headers;
};
