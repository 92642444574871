import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';

import { ROUTES } from '@config';

import { useResetPasswordNoAuth } from '@hooks';

import styles from './ForgotPasswordForm.module.scss';

const ForgotPasswordForm = () => {
  const email = useRef('');
  const resetPassword = useResetPasswordNoAuth();
  const [emailSent, setEmailSent] = useState(false);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const success = await resetPassword(email.current);
    if (success) setEmailSent(true);
  };

  return (
    <form className={styles.formContainer} onSubmit={onSubmit}>
      <div className={styles.title}>
        <Trans>Reset password</Trans>
      </div>
      <Input
        className={styles.input}
        prefix={<UserOutlined />}
        size="large"
        type="email"
        name="email"
        onChange={(event) => (email.current = event.target.value)}
        placeholder="Email"
        required
        data-testid="email-input"
      />
      <Button
        className={`${styles.button} button-theme2`}
        // className={`${styles.button}`}
        disabled={emailSent}
        type="primary"
        shape="round"
        htmlType="submit"
        data-testid="submit-button"
      >
        <Trans>Proceed</Trans>
      </Button>
      <Link to={ROUTES.LOGIN}>
        <div className={styles.goToLogin}>
          <Trans>Go to login</Trans>
        </div>
      </Link>
    </form>
  );
};

export default ForgotPasswordForm;
