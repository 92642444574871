import { useReducer } from 'react';

import {
  OptionsContext,
  initialOptionsState,
  optionsReducer,
  //
  UserContext,
  initialUserState,
  userReducer,
} from '@reducers';

const WithProviders = ({ children }: React.PropsWithChildren) => {
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);
  const [OptionsState, optionsDispatch] = useReducer(
    optionsReducer,
    initialOptionsState,
  );

  return (
    <UserContext.Provider value={{ state: userState, dispatch: userDispatch }}>
      <OptionsContext.Provider
        value={{ state: OptionsState, dispatch: optionsDispatch }}
      >
        {children}
      </OptionsContext.Provider>
    </UserContext.Provider>
  );
};

export default WithProviders;
