import { FlowBuilder, FlowExecutionButton } from '@components';

import { useActiveFlowUuid, useFlowSchema } from '@hooks';

import { scssVariables } from '@styles';

const DashboardFlowSchema = () => {
  const activeFlowUuid = useActiveFlowUuid();
  const nodes = useFlowSchema(activeFlowUuid);

  return (
    <div
      style={{
        height: scssVariables.dashboardSchemaHeight,
        position: 'relative',
        width: '100%',
      }}
    >
      <FlowBuilder key={activeFlowUuid} nodes={nodes} editable={false} />
      {activeFlowUuid && <FlowExecutionButton />}
    </div>
  );
};

export default DashboardFlowSchema;
