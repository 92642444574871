import { ComponentProps } from 'react';
import { Form } from 'antd';

import styles from './FormItem.module.scss';

type FormItemProps = ComponentProps<typeof Form.Item> & {
  children: React.ReactNode;
  label?: string;
};

const FormItem = ({ children, label, ...otherProps }: FormItemProps) => {
  return (
    <div className={styles.container}>
      {label && <span className={styles.label}>{label}</span>}
      <Form.Item style={{ margin: 0 }} {...otherProps}>
        {children}
      </Form.Item>
    </div>
  );
};

export default FormItem;
